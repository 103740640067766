import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import {InputAdornment, TextField} from "@mui/material";
import React from "react";
import {SearchSettings} from "../../interfaces/SearchSettings";
import {useThemeContext} from "../../theme/themeContextProvider";
import {FilterState} from "./categoryDialog.component";
import {useTranslation} from "react-i18next";

interface MafiSearchInputFilterComponentProps {
    searchSettings: SearchSettings & FilterState;
    setSearchSettings: React.Dispatch<React.SetStateAction<SearchSettings & FilterState>>;
}

const MafiSearchInputFilterComponent = ({searchSettings, setSearchSettings}: MafiSearchInputFilterComponentProps) => {
    const {t} = useTranslation();
    const {isDarkMode} = useThemeContext();

    return (
        <TextField
            className={isDarkMode ? "mafi-search" : ""}
            label={t('GENERAL.SEARCH')}
            variant={"filled"}
            sx={{minWidth: "100%", backgroundColor: "white", borderRadius: "6px"}}
            value={searchSettings && searchSettings.searchText ? searchSettings.searchText : ""}
            onChange={event => setSearchSettings((prevState) => {
                return {...prevState, searchText: event.target.value.toUpperCase()}
            })}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon color={"primary"}/>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment onClick={() => {
                        setSearchSettings((prevState) => {
                            return {...prevState, searchText: ""}
                        })
                    }} position="end">
                        <HighlightOffOutlinedIcon color={"primary"}/>
                    </InputAdornment>
                )
            }}>
        </TextField>
    );
};

export default MafiSearchInputFilterComponent;
