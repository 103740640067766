import React from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOff";
import {Box, FormControl, InputAdornment, InputLabel, MenuItem, Select} from "@mui/material";
import {useAppSelector} from "../../store/hooks";
import {useThemeContext} from "../../theme/themeContextProvider";
import {useTranslation} from "react-i18next";
import {AvailableSearchOptions} from "../../utils/mafiSearchHelper";

interface MafiOwnerFilterSelectProps {
    selectedOwner: string;
    onChange: (owner: string) => void;
    availableSearchOptions?: AvailableSearchOptions;
}

const MafiOwnerFilterSelectComponent = ({selectedOwner, onChange, availableSearchOptions}: MafiOwnerFilterSelectProps) => {
    const {isDarkMode} = useThemeContext();
    const {t} = useTranslation();

    const mafiOwners = useAppSelector(state => state.searchSettings).searchOwners;

    return (
        <Box sx={{backgroundColor: 'white', borderRadius: "6px", height: "56px"}}>
            <FormControl className={isDarkMode ? "mafi-search" : ""} variant={"filled"}
                         sx={{width: '100%'}}>
                <InputLabel id={"owner-label"}>{t('GENERAL.OWNER')}</InputLabel>
                <Select
                    labelId={"owner-label"}
                    sx={{padding: 0, margin: 0, width: '100%'}}
                    onChange={(event) => {
                        if (event.target.value === "ALL") {
                            onChange("ALL");
                        } else {
                            const owner = mafiOwners.find(own => own.mafiOwnerCode === event.target.value);
                            onChange(owner ? owner.mafiOwnerCode : "ALL");
                        }
                    }}
                    endAdornment={
                        <InputAdornment
                            position="end"
                            onClick={() => {
                                onChange("ALL");
                            }}
                            sx={{ pointer: "cursor", zIndex: 1000, position: "absolute", right: 35 }}>
                            <HighlightOffOutlinedIcon color={"primary"}/>
                        </InputAdornment>
                    }
                    value={selectedOwner}>
                    <MenuItem value={"ALL"}>{t('GENERAL.ALL')}</MenuItem>
                    {mafiOwners.map((c) => <MenuItem key={c.id}
                                                     disabled={availableSearchOptions && !availableSearchOptions?.owner.includes(c.mafiOwnerCode)}
                                                     value={c.mafiOwnerCode}>{c.mafiOwnerCode} ({c.mafiOwnerText})</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    )
}

export default MafiOwnerFilterSelectComponent;
