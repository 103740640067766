import React, {useCallback, useEffect, useRef, useState} from "react";

import {
    Box,
    Chip,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import MafiOwnerFilterSelectComponent from "../../components/mafiSearch/mafiOwnerFilterSelect.component";
import MafiPrefixFilterSelectComponent from "../../components/mafiSearch/mafiPrefixFilterSelect.component";
import MafiSearchCustomFooter from "../../components/mafiSearch/MafiSearchCustomFooter.component";
import MafiSearchInputFilterComponent from "../../components/mafiSearch/mafiSearchInputFilter.component";
import {allLashingMethods, allLengthEntries, allTaraEntries} from "../../misc/filterOptions";
import {decoupleMafi} from "../../misc/mafiHelper";
import {setActiveTutorial, setShowLoading} from "../../store/slices/global";
import {defaultTableStyle} from "../../styles/defaultStyles";
import {
    DataGridPremium,
    DataGridPremiumProps,
    useGridApiRef
} from "@mui/x-data-grid-premium";
import {useDebounce, MafiCategory, MafiInformation, MafiPrefix, MafiOwner} from "@blg/blg-core";
import {LicenseInfo} from "@mui/x-license-pro";
import CategoryDialogComponent, {
    CategoryDialogRef,
    FilterState
} from "../../components/mafiSearch/categoryDialog.component";
import TugService from "@blg/blg-core/lib/esm/services/roTrail/roTrailTugService";
import {store} from "../../store/store";
import {setSelectedMafi} from "../../store/slices/mafi";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../routes/routes";
import {
    setLastSearchSettings,
    setSearchCategories,
    setSearchOwners,
    setSearchPrefix,
} from "../../store/slices/searchSettings";
import {useAppSelector} from "../../store/hooks";
import StackGridTreeDataGroupingCell from "../../components/StackGridTreeDataGroupingCell";
import {darken, lighten} from '@mui/material/styles';
import {SearchSettings} from "../../interfaces/SearchSettings";
import {useThemeContext} from "../../theme/themeContextProvider";
import MafiSearchHelper, {AvailableSearchOptions} from "../../utils/mafiSearchHelper";
import {defaultContentStyle} from "../bypassDetails.view";
import ConfirmDialogComponent from "@blg/blg-core/lib/esm/components/dialog/confirmDialog.component";

const stackHeaderStyle = {
    //  maxHeight: '25px !important',
    // minHeight: '25px !important',
}

const MafiSearchView: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const apiRef = useGridApiRef();
    const {isDarkMode} = useThemeContext();

    const categoryDialogRef = useRef<CategoryDialogRef>(null);

    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY!);

    const isAttached = useAppSelector(state => state.mafi).isAttached;
    const attachedMafi = useAppSelector(state => state.mafi).attachedMafi;
    const mafiCategories = useAppSelector(state => state.searchSettings).searchCategories;
    const mafiPrefix = useAppSelector(state => state.searchSettings).searchPrefix;
    const mafiOwners = useAppSelector(state => state.searchSettings).searchOwners;
    const lastSearchSettings = useAppSelector(state => state.searchSettings).lastSearchSettings;

    const [showConfirmDialog, setShowConfirmDialog] = useState<{ show: boolean, mafi?: MafiInformation }>({
        show: false,
        mafi: undefined
    });

    const [columns, setColumns] = useState<Array<{
        field: string,
        headerName: string,
        width?: number,
        sortable?: boolean
    }>>([]);

    const [debouncedSearchSettings, searchSettings, setSearchSettings] = useDebounce<SearchSettings & FilterState>({
        prefix: "ALL",
        owner: "ALL",
        searchText: "",
        categories: [],
        length: [],
        tara: [],
        lashingMethods: [],
        loadingStatus: [],
        showOnlyPoolMafi: false
    }, 250);
    const [availableSearchOptions, setAvailableSearchOptions] = useState<AvailableSearchOptions | undefined>(undefined);

    // Pagination
    const [rowCount, setRowCount] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [showCategoryDialog, setShowCategoryDialog] = useState<boolean>(false);

    const [filteredMafis, setFilteredMafis] = useState<MafiInformation[]>([]);
    const [additionalMafis, setAdditionalMafis] = useState<string[]>([]);

    useEffect(() => {
        store.dispatch(setActiveTutorial("MAFI_SEARCH"));

        void loadFilterData();

        const tableInformation = new MafiInformation().getTableInformation();
        tableInformation.forEach(info => info.headerName = t(info.headerName));
        setColumns(tableInformation);
    }, []);

    useEffect(() => {
        if (isFilterActive()) {
            if (page > 0) {
                setPage(0);
            } else {
                void loadMafis();
            }
        } else {
            setFilteredMafis([]);
            setAvailableSearchOptions({
                category: mafiCategories,
                lashingMethods: allLashingMethods,
                length: allLengthEntries,
                owner: mafiOwners.map(o => o.mafiOwnerCode),
                prefix: mafiPrefix.map(p => p.prefix),
                tara: allTaraEntries
            });
        }
    }, [debouncedSearchSettings]);

    function isFilterActive(): boolean {
        return (searchSettings?.searchText && searchSettings.searchText.length > 0) || searchSettings?.owner !== "ALL"
            || searchSettings.prefix !== "ALL" || searchSettings.categories.length > 0 || searchSettings.length.length > 0
            || searchSettings.tara.length > 0 || searchSettings.lashingMethods.length > 0 || searchSettings.loadingStatus.length > 0
            || searchSettings.showOnlyPoolMafi;
    }

    async function resetFilterOptions(prefixes: MafiPrefix[]) {
        const mafiResponse = await MafiSearchHelper.instance.handleMafiSearch({
                mafiNo: searchSettings?.searchText && searchSettings?.searchText !== "" ? searchSettings?.searchText : undefined,
                mafiPrefix: searchSettings?.prefix,
                mafiOwner: searchSettings?.owner,
                mafiCategories: searchSettings.categories ? searchSettings.categories.map(cat => cat.category) : [],
                lashingMethods: searchSettings.lashingMethods,
                loadingStatus: searchSettings?.loadingStatus,
                mafiLength: searchSettings.length,
                mafiMaxWeightLoad: searchSettings.tara,
                showOnlyPoolMafi: searchSettings.showOnlyPoolMafi,
                pageIndex: page,
                pageLimit: pageSize
            }, prefixes.map(prefix => prefix.prefix),
            allLengthEntries,
            allTaraEntries,
            allLashingMethods,
            mafiCategories
        );

        setAvailableSearchOptions(mafiResponse.availableSearchOptions);
    }

    /**
     * is called when a row of the mafi search is selected,
     * navigates to the mafi details
     * @param row
     */
    async function onRowSelected(row: MafiInformation) {
        // Detach the previous mafi first
        if (attachedMafi && isAttached) {
            await decoupleMafi(attachedMafi!);
        }

        store.dispatch(setSelectedMafi(row));
        navigate(ROUTES.MAFI_DETAILS);
    }

    /**
     * is called when category select is tapped, opens the category dialog
     */
    function openCategoryDialog(): void {
        setShowCategoryDialog(true);
    }

    /**
     * is called when the category dialog should be closed
     */
    function closeCategoryDialog(): void {
        setShowCategoryDialog(false);
    }

    /**
     * is called when the category has changed, updates category and closes the dialog
     * @param activeFilters
     */
    function onFilterChanged(activeFilters: FilterState) {
        setSearchSettings((prevState) => {
            return {...prevState, ...activeFilters}
        });
    }

    async function loadMafis() {
        try {
            store.dispatch(setShowLoading({showLoading: true}));
            const mafiResponse = await MafiSearchHelper.instance.handleMafiSearch({
                    mafiNo: searchSettings?.searchText && searchSettings?.searchText !== "" ? searchSettings?.searchText : undefined,
                    mafiPrefix: searchSettings?.prefix,
                    mafiOwner: searchSettings?.owner,
                    mafiCategories: searchSettings.categories ? searchSettings.categories.map(cat => cat.category) : [],
                    lashingMethods: searchSettings.lashingMethods,
                    loadingStatus: searchSettings.loadingStatus,
                    showOnlyPoolMafi: searchSettings.showOnlyPoolMafi,
                    mafiLength: searchSettings.length,
                    mafiMaxWeightLoad: searchSettings.tara,
                    pageIndex: page,
                    pageLimit: pageSize
                }, mafiPrefix.map(prefix => prefix.prefix),
                allLengthEntries,
                allTaraEntries,
                allLashingMethods,
                mafiCategories
            );

            setAvailableSearchOptions(mafiResponse.availableSearchOptions);

            store.dispatch(setLastSearchSettings({
                owner: searchSettings?.owner !== "ALL" ? searchSettings?.owner : "ALL",
                prefix: searchSettings?.prefix !== "ALL" ? searchSettings?.prefix : "ALL",
                searchText: searchSettings?.searchText && searchSettings?.searchText !== "" ? searchSettings.searchText : undefined,
                categories: searchSettings?.categories || [],
                length: searchSettings?.length || [],
                tara: searchSettings?.tara || [],
                lashingMethods: searchSettings?.lashingMethods || [],
                loadingStatus: searchSettings?.loadingStatus || [],
                showOnlyPoolMafi: searchSettings?.showOnlyPoolMafi || false
            }));

            const paginationInfo = mafiResponse.pageInfo;
            setRowCount(paginationInfo.totalElements);

            let mafis = [...mafiResponse.searchMafis, ...mafiResponse.stackedMafis];

            setAdditionalMafis(mafiResponse.stackedMafis.map(m => m.mafiNo));

            mafis = mafis.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.mafiNo === value.mafiNo
                )));

            mafis.forEach(mafi => {
                if (mafi.stackNo && mafi.stackNo.length > 0) {
                    mafi.tableStack = [mafi.stackNo, mafi.mafiNo];
                    mafi.stack = mafis.filter(m => m.stackNo === mafi.stackNo).map(m => m.mafiNo);
                } else {
                    mafi.tableStack = [mafi.mafiNo];
                }
            });

            const stackMafis = mafis.filter(m => m.stackNo);
            const withoutStack = mafis.filter(m => !m.stackNo);

            setFilteredMafis([...withoutStack, ...stackMafis.sort((a, b) => parseInt(a.stackNo) - parseInt(b.stackNo))]);
        } catch (e) {
            console.log('loadMafis', e);
            // TODO: Show custom error
        } finally {
            store.dispatch(setShowLoading({showLoading: false}));
        }
    }

    async function loadFilterData() {
        try {
            store.dispatch(setShowLoading({showLoading: true}));
            const settingsResponse = await TugService.instance.loadMafiSearchSettings();
            const mafiCategories = MafiCategory.parseFromArray(settingsResponse.data.categoryList) as MafiCategory[];
            const mafiOwners = MafiCategory.parseFromArray(settingsResponse.data.ownerList) as MafiOwner[];
            const mafiPrefix = MafiPrefix.parseFromArray(settingsResponse.data.prefixList) as MafiPrefix[];
            
            store.dispatch(setSearchCategories(mafiCategories));
            store.dispatch(setSearchPrefix(mafiPrefix.sort((a, b) => a.prefix.localeCompare(b.prefix))));
            store.dispatch(setSearchOwners(mafiOwners.sort((a, b) => a.mafiOwnerCode.localeCompare(b.mafiOwnerCode))));

            setTimeout(() => {
                if (lastSearchSettings) {
                    setSearchSettings(lastSearchSettings);
                } else {
                    resetFilterOptions(mafiPrefix);
                }
            }, 200);
        } catch (e) {

        } finally {
            store.dispatch(setShowLoading({showLoading: false}));
        }
    }

    const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) => {
        return row.tableStack;
    };

    const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
        headerName: 'StackNo',
        headerClassName: 'cargotable-header',
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => {
            if (apiRef && apiRef.current) {
                const findMafi = filteredMafis.find((mafi) => mafi.stackNo === params.value);
                return <StackGridTreeDataGroupingCell {...params} innerRef={apiRef} mafi={findMafi}/>
            }
            return <Box></Box>
        },
    };

    const getBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.4);

    const allActiveFilters: string[] = searchSettings.categories.map(cat => cat.category)
        .concat(searchSettings.length.map(len => len.text))
        .concat(searchSettings.tara.map(tara => tara.text))
        .concat(searchSettings.loadingStatus.map(ls => t(`GENERAL.${ls}`)))
        .concat(searchSettings.lashingMethods.map(lm => t(`GENERAL.${lm}`)));

    if (searchSettings.showOnlyPoolMafi) {
        allActiveFilters.push(t('MAFI_SEARCH.POOL'));
    }

    const CustomFooter = useCallback(() => <MafiSearchCustomFooter onReloadMafis={() => {
        void loadMafis();
    }}/>, [searchSettings]);

    return (
        <Box sx={{
            height: "100%", width: '100%', display: 'flex', flexDirection: 'column',
            backgroundColor: theme.palette.background.default
        }}>
            <Box sx={{
                width: "100%", display: "flex", justifyContent: "space-between",
                backgroundColor: theme.palette.primary.main, pb: 2, px: 3
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={2.9}>
                        <MafiSearchInputFilterComponent searchSettings={searchSettings}
                                                        setSearchSettings={setSearchSettings}/>
                    </Grid>
                    <Grid item xs={2.9}>
                        <MafiOwnerFilterSelectComponent
                            availableSearchOptions={availableSearchOptions}
                            selectedOwner={searchSettings && searchSettings.owner ? searchSettings.owner : "ALL"}
                            onChange={(owner) => {
                                setSearchSettings((prevState) => {
                                    return {
                                        ...prevState,
                                        owner: owner
                                    }
                                });
                            }}/>
                    </Grid>
                    <Grid item xs={2.9}>
                        <MafiPrefixFilterSelectComponent
                            availableSearchOptions={availableSearchOptions}
                            selectedPrefix={searchSettings && searchSettings.prefix ? searchSettings.prefix : "ALL"}
                            onChange={(prefix) => {
                                setSearchSettings((prevState) => {
                                    return {
                                        ...prevState,
                                        prefix: prefix
                                    }
                                });
                            }}/>
                    </Grid>
                    <Grid item xs={2.9}>
                        <Box sx={{backgroundColor: 'white', borderRadius: "6px", height: "56px"}}>
                            <FormControl className={isDarkMode ? "mafi-search" : ""} variant={"filled"}
                                         sx={{width: '100%'}} onClick={() => {
                                openCategoryDialog();
                            }
                            }>
                                <InputLabel id={"select-label"}>{t('MAFI_SEARCH.EXTENDED_FILTERS')}</InputLabel>
                                <Select
                                    labelId={"select-label"}
                                    readOnly
                                    sx={{padding: 0, margin: 0, width: '100%'}}
                                    value={allActiveFilters}
                                    renderValue={() => (
                                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                            {allActiveFilters.map((value) => (
                                                <Chip size={"small"} key={value} label={value}/>
                                            ))}
                                        </Box>
                                    )}>
                                    <MenuItem key={allActiveFilters.join(',')} value={allActiveFilters.join(',')}>
                                    </MenuItem>)
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={0.4}>
                        <IconButton
                            sx={{
                                whiteSpace: 'nowrap',
                                textTransform: 'none',
                                height: '56px',
                                width: '56px',
                            }}
                            aria-label="reset"
                            color="white"
                            onClick={() => {
                                setAvailableSearchOptions(undefined);
                                setSearchSettings({
                                    prefix: "ALL",
                                    owner: "ALL",
                                    searchText: "",
                                    categories: [],
                                    length: [],
                                    tara: [],
                                    lashingMethods: [],
                                    loadingStatus: [],
                                    showOnlyPoolMafi: false
                                });

                                categoryDialogRef.current!.resetFilter();

                                store.dispatch(setLastSearchSettings({
                                    owner: "ALL",
                                    prefix: "ALL",
                                    searchText: undefined,
                                    categories: [],
                                    length: [],
                                    tara: [],
                                    lashingMethods: [],
                                    loadingStatus: [],
                                    showOnlyPoolMafi: false
                                }));
                            }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{p: 3, height: '100%'}}>
                <Box sx={{
                    ...defaultContentStyle(theme).contentBox, height: "calc(100vh - 64px - 125px)", '& .stack-header': {
                        bgcolor: (theme) =>
                            `${getBackgroundColor(theme.palette.surface.main, theme.palette.mode)} !important`,
                        ...stackHeaderStyle,
                        ".MuiDataGrid-cell--withRenderer": stackHeaderStyle,
                        ".MuiDataGrid-cell": stackHeaderStyle,
                    }, '& .highlighted-mafi': {
                        bgcolor: (theme) =>
                            `${getBackgroundColor(theme.palette.yellowText.main, theme.palette.mode)} !important`
                    }, '& .locked-mafi': {
                        color: isDarkMode ? "black !important" : theme.palette.text.primary,
                        bgcolor: (theme) =>
                            `${getBackgroundColor(theme.palette.yellowText.main, theme.palette.mode)} !important`
                    }, '& .stack-mafi': {
                        color: isDarkMode ? "black !important" : theme.palette.text.primary,
                        bgcolor: (theme) =>
                            `${getBackgroundColor(theme.palette.greyCustom.light, theme.palette.mode)} !important`
                    }
                }}>
                    <DataGridPremium
                        localeText={{
                            noRowsLabel: isFilterActive() ? t('TABLE_DATA.NO_RESULTS') : t('TABLE_DATA.SELECT_FILTER'),
                            noResultsOverlayLabel: isFilterActive() ? t('TABLE_DATA.NO_RESULTS') : t('TABLE_DATA.SELECT_FILTER'),
                        }}
                        apiRef={apiRef}
                        treeData
                        disableColumnPinning
                        disableColumnResize
                        sx={defaultTableStyle(theme)}
                        columnHeaderHeight={40}
                        rows={filteredMafis}
                        columns={columns}
                        paginationModel={{pageSize: pageSize, page: page}}
                        pagination
                        onPaginationModelChange={(pagination) => {
                            setPage(pagination.page);
                            setPageSize(pagination.pageSize)
                        }}
                        rowCount={rowCount}
                        getRowId={(row) => row.mafiNo}
                        onCellClick={(item) => {
                            if (item.row.coupled) {
                                apiRef.current.selectRow(item.rowNode.id, false);
                                setShowConfirmDialog({show: true, mafi: item.row});
                                return;
                            } else if (!(item.rowNode as any).isAutoGenerated) {
                                onRowSelected(item.row)
                            } else {
                                apiRef.current.setRowChildrenExpansion(item.id, !(item.rowNode as any).childrenExpanded);
                            }
                        }
                        }
                        getRowClassName={(p) => {
                            if (Object.keys(p.row).length === 0) {
                                return 'stack-header';
                            } else if (p.row.coupled) {
                                return 'locked-mafi';
                            } else if (additionalMafis.includes((p.row as MafiInformation).mafiNo)) {
                                return 'stack-mafi';
                            }

                            return '';
                        }
                        }
                        getTreeDataPath={getTreeDataPath}
                        groupingColDef={groupingColDef}
                        defaultGroupingExpansionDepth={1}
                        slots={{
                            footer: CustomFooter,
                        }}
                    ></DataGridPremium>
                </Box>
            </Box>
            {/* dialog to select / filter for a category */}
            <CategoryDialogComponent
                ref={categoryDialogRef}
                availableSearchOptions={availableSearchOptions}
                allMafiCategories={mafiCategories}
                open={showCategoryDialog}
                onFilterChanged={onFilterChanged}
                onCloseDialog={closeCategoryDialog}
            />
            <ConfirmDialogComponent
                showDialog={showConfirmDialog.show}
                declineEvent={() => setShowConfirmDialog({show: false, mafi: undefined})}
                confirmEvent={() => {
                    onRowSelected(showConfirmDialog.mafi!);
                }}
                title={t("CONFIRM_ATTACH_DIALOG.TITLE")}
                text={t("CONFIRM_ATTACH_DIALOG.TEXT")}
                confirmText={t("GENERAL.ATTACH")}
            />
        </Box>
    );
};

export default MafiSearchView;
