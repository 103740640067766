import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import FilterElementComponent from "./filterElement.component";

interface LoadingStatusFilterContainerProps {
    loadingStatus: string[],
    currentSelectedStatus: string[],
    onLoadingStatusChanged: (loadingStatus: string[]) => void
}

const LoadingStatusFilterContainerComponent = (props: LoadingStatusFilterContainerProps) => {
    const {t} = useTranslation();

    function onCurrentMethodChanged(method: string) {
        if (props.currentSelectedStatus.indexOf(method) === -1) {
            props.onLoadingStatusChanged([...props.currentSelectedStatus, method]);
        } else {
            props.onLoadingStatusChanged(props.currentSelectedStatus.filter(m => m !== method));
        }
    }

    const allFilterElements = props.loadingStatus.map(status =>
        <FilterElementComponent
            text={t(`GENERAL.${status}`)}
            value={status}
            isSelected={props.currentSelectedStatus.indexOf(status) !== -1}
            key={status}
            onClick={onCurrentMethodChanged}/>);

    return <Box display={'flex'} flexDirection={'column'}>
        <Typography sx={{ textDecoration: 'underline' }} variant={"h6"}>
            {t('TASK_SELECTION.MAFI_SEARCH.FILTER_DIALOG.LOADING_STATUS')}
        </Typography>
        {allFilterElements}
    </Box>;
}

export default LoadingStatusFilterContainerComponent;
