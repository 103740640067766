import React from 'react';
import { MafiInformation } from "@blg/blg-core";
import {Box, Button, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {generatePath} from "react-router";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../routes/routes";
import {useThemeContext} from "../../theme/themeContextProvider";
import {defaultContentStyle} from "../../views/bypassDetails.view";

interface MafiStackProps {
    mafi: MafiInformation
    unstackDisabled: boolean
}

const MafiStackComponent = ({mafi, unstackDisabled}: MafiStackProps) => {
    const { isDarkMode } = useThemeContext();
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const unstackClicked = () => {
        const path = generatePath(ROUTES.UNSTACK_DETAILS, {'mafiNo': mafi.mafiNo});
        navigate(path);
    }

    const mafiElements = mafi.stack.map((element: string, index) => {
        const isHighlighted = mafi.mafiNo === element;

        return (
            <Box key={element}
                 sx={{
                     border: `${ isHighlighted ?
                         `1px solid #F5C341` :
                         `1px solid ${theme.palette.background.default}` }`,
                 background: `${ isHighlighted ?
                     `rgba(245, 195, 65, 0.2)` :
                     theme.palette.surface.main}`}}
                 style={ defaultContentStyle(theme).stackElement }>
                <Typography sx={{px: 3, fontSize: 24}}>{index + 1}</Typography>
                <Typography
                    sx={{display: "flex", justifyContent: "center", flex: 1, fontSize: 24}}>{element}</Typography>
            </Box>
        )
    });

    return (
        <Box sx={{mt: 3, display: "flex", flexDirection: "column"}}>
            <Typography sx={{fontSize: 24, fontWeight: 600, color: 'black'}}>
                Stackinfo
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", maxHeight: 250, overflowY: "auto" }}>
                {mafiElements.reverse()}
            </Box>
            <Button
                disabled={unstackDisabled}
                onClick={unstackClicked}
                variant={"contained"}
                sx={{
                    backgroundColor: `${isDarkMode ? "rgba(251, 251,252, 0.15)" : theme.palette.primary.main}`,
                    textTransform: 'none', mt: "10px", height: 50, borderRadius: '12px'
                }}>
                <Typography sx={{ color: theme.palette.white.main, fontSize: 24}}>
                    {t('GENERAL.UNSTACK')}
                </Typography>
            </Button>
        </Box>
    )
}

export default MafiStackComponent;
