import {useTheme} from "@mui/material/styles";
import React, {useEffect, useRef, useState} from "react";
import {Backdrop, Box, Typography} from "@mui/material";
import {Outlet, useNavigate} from "react-router-dom";
import {useAppSelector} from "../store/hooks";
import LoadingImage from "../assets/images/loading-animation.svg";
import LoadingImageWhite from "../assets/images/loading-animation-white.svg";
import NavigationComponent from "../components/navigation/navigation.component";
import {Emitter, EmitterEvents} from "@blg/blg-core";
import {ROUTES} from "routes/routes";
import {calcCoordsDistance} from "../misc/notationHelper";
import {store} from "../store/store";
import {setDriverPosition} from "../store/slices/driver";
import {setRefreshToken, setToken} from "../store/slices/auth";
import {useThemeContext} from "../theme/themeContextProvider";
import MafiSearchHelper from "../utils/mafiSearchHelper";

const HomeView: React.FC = () => {
        const {isDarkMode} = useThemeContext();
        const theme = useTheme();
        const globalStore = useAppSelector(state => state.global);
        const isLoading = globalStore.loading;
        const navigate = useNavigate();

        const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
        const [loadingText, setShowLoadingText] = useState<string | undefined>(undefined);
        const [watchId, setWatchID] = useState<number | null>(null);
        const [tempLocation, setTempLocation] = useState<GeolocationPosition | null>(null);

        const logoutTimer = useRef<NodeJS.Timeout | null>(null);
        const reloadMafiTimer = useRef<NodeJS.Timeout | null>(null);

        const driverLatitude = useAppSelector(state => state.driver).latitude;
        const driverLongitude = useAppSelector(state => state.driver).longitude;

        const refreshToken = useAppSelector(state => state.auth).refreshToken;

        useEffect(() => {
            setShowLoadingAnimation(isLoading.showLoading);
            setShowLoadingText(isLoading.loadingText);
        }, [isLoading]);

        useEffect(() => {
            checkLogin();

            Emitter.on(EmitterEvents.TOKEN_EXPIRED, () => {
                navigate(ROUTES.LOGIN);
            });

            Emitter.on(EmitterEvents.TOKEN_REFRESHED, (payload: { accessToken: string, refreshToken: string }) => {
                store.dispatch(setRefreshToken(payload.refreshToken));
                store.dispatch(setToken(payload.accessToken));
            });

            reloadMafiTimer.current = setInterval(async () => {
                await MafiSearchHelper.instance.syncOfflineData({ showLoadingAnimation: false, hideLoadingText: true });
            }, 10 * 60000); // Reload every 10 minutes

            if (window.api) {
                window.api.getLocation();
                window.api.receiveLocation((data: { latitude: number, longitude: number }) => {
                    console.log('received data', data);
                    if (data.latitude && data.longitude) {
                        setTempLocation({
                            coords: {
                                altitudeAccuracy: 0,
                                accuracy: 1,
                                altitude: 0,
                                heading: 0,
                                speed: 1,
                                latitude: data.latitude,
                                longitude: data.longitude
                            },
                            timestamp: 0
                        })
                    }
                });
            } else {
                // Watch the driver position and save it in the store
                const id = navigator.geolocation.watchPosition((position) => {
                        if (position.coords.latitude && position.coords.longitude) {
                            setTempLocation(position);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }, {enableHighAccuracy: true});
                setWatchID(id);
            }

            return () => {
                Emitter.removeAllListeners();

                if (logoutTimer && logoutTimer.current) {
                    clearTimeout(logoutTimer.current);
                }

                if (reloadMafiTimer && reloadMafiTimer.current) {
                    clearTimeout(reloadMafiTimer.current);
                }

                // Clear the watcher on leaving the view
                if (watchId)
                    navigator.geolocation.clearWatch(watchId);
            }
        }, []);

        useEffect(() => {
            if (tempLocation) {
                checkLocation(tempLocation);
            }
        }, [tempLocation])

        const checkLocation = (position: GeolocationPosition) => {
            if (driverLongitude && driverLatitude) {
                const distance = calcCoordsDistance({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    },
                    {latitude: driverLatitude, longitude: driverLongitude});

                // Only update the location if the distance is bigger than x meters
                console.log('new distance', distance);
                if (distance > 2) {
                    store.dispatch(setDriverPosition({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            accuracy: position.coords.accuracy
                        }
                    ));
                }
            } else if (position.coords.longitude && position.coords.latitude) {
                store.dispatch(setDriverPosition({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        accuracy: position.coords.accuracy
                    }
                ));
            }
        }

        const checkLogin = () => {
            logoutTimer.current = setTimeout(() => {
                const tokenIsValid = validateToken(refreshToken);
                if (!tokenIsValid) {
                    goToLogin();
                } else {
                    checkLogin();
                }
            }, 30000);
        }

        const goToLogin = () => {
            localStorage.removeItem(`persist:${process.env.REACT_APP_STORE_KEY}`);
            setShowLoadingAnimation(false);
            navigate(ROUTES.LOGIN);
        };

        /**
         * Check if the token is valid
         * @param token
         */
        const validateToken = (token?: string): boolean => {
            if (!token) {
                return false;
            }

            const arrayToken = token.split('.');
            const tokenPayload = JSON.parse(atob(arrayToken[1]));
            return !(new Date().getTime() >= (new Date(tokenPayload?.exp * 1000).getTime()));
        }

        return (
            <Box sx={{display: "flex", height: "100vh", width: "100%"}}>
                <Box sx={{position: "absolute", top: 0, left: 0}}>
                    <Backdrop
                        sx={{
                            backgroundColor: `${isDarkMode ? "rgba(36, 26, 5,0.8)" : "rgba(222,224,233,0.9)"}`,
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                            display: "flex", flexDirection: "column"
                        }}
                        open={showLoadingAnimation}
                    >
                        <object type="image/svg+xml" style={{width: 275, height: 275}}
                                data={isDarkMode ? LoadingImageWhite : LoadingImage}>svg-animation
                        </object>
                        {loadingText &&
                            <Typography color={isDarkMode ? theme.palette.white.main : theme.palette.text.primary}
                                        variant={"h5"}>
                                {loadingText}
                            </Typography>
                        }
                    </Backdrop>
                </Box>
                <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <NavigationComponent/>
                    <Box sx={{
                        backgroundColor: theme.palette.surface.main, height: "100%", width: "100%", boxSizing: "border-box",
                        maxHeight: "calc(100vh - 64px)"
                    }}>
                        <Outlet/>
                    </Box>
                </Box>
            </Box>
        );
    }
;

export default HomeView;
