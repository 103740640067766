import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {FilterState} from "../../components/mafiSearch/categoryDialog.component";
import {SearchSettings} from "../../interfaces/SearchSettings";
import { MafiCategory, MafiOwner, MafiPrefix } from "@blg/blg-core";

export interface SearchSettingsState {
    searchCategories: MafiCategory[]
    searchOwners: MafiOwner[]
    searchPrefix: MafiPrefix[],
    lastSearchSettings?: SearchSettings & FilterState
}

const initialState: SearchSettingsState = {
    searchCategories: [],
    searchOwners: [],
    searchPrefix: [],
    lastSearchSettings: undefined
};

const searchSettingsState = createSlice({
    name: "searchSettings",
    initialState,
    reducers: {
        setSearchCategories(state: SearchSettingsState, action: PayloadAction<MafiCategory[]>) {
            state.searchCategories = action.payload;
        },
        setSearchOwners(state: SearchSettingsState, action: PayloadAction<MafiOwner[]>) {
            state.searchOwners = action.payload;
        },
        setSearchPrefix(state: SearchSettingsState, action: PayloadAction<MafiPrefix[]>) {
            state.searchPrefix = action.payload;
        },
        setLastSearchSettings(state: SearchSettingsState, action: PayloadAction<SearchSettings & FilterState | undefined>) {
            state.lastSearchSettings = action.payload;
        },
    },
});

export const {setSearchCategories, setSearchPrefix, setSearchOwners, setLastSearchSettings} = searchSettingsState.actions;
export default searchSettingsState.reducer;
