import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import TeamModel from "../../models/api/Team.model";

export interface TeamState {
  teams: TeamModel[],
  lastSelectedTeam: TeamModel | undefined,
}

const initialState: TeamState = {
  teams: [],
  lastSelectedTeam: undefined
};

const teamState = createSlice({
  name: "teams",
  initialState,
  reducers: {
    setTeams(state: TeamState, action: PayloadAction<TeamModel[]>) {
      state.teams = action.payload;
    },
    setLastSelectedTeam(state: TeamState, action: PayloadAction<TeamModel | undefined>) {
      state.lastSelectedTeam = action.payload;
    }
  },
});

export const { setTeams, setLastSelectedTeam } = teamState.actions;
export default teamState.reducer;
