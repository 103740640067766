import axios, {AxiosInstance, AxiosResponse} from "axios";

export default class RfidService {

    private static _instance?: RfidService; // Used for the singleton pattern

    // Create the singleton
    static get instance() {
        return RfidService._instance ?? (RfidService._instance = new RfidService());
    }

    protected axiosClient!: AxiosInstance;
    protected baseURL: string = process.env.REACT_APP_RFID_URL!;

    constructor() {
        console.info("using rfid host: ", this.baseURL);
        this.createHttpClient();

        if (RfidService._instance) {
            return RfidService._instance;
        }

        RfidService._instance = this;
    }

    private createHttpClient() {
        this.axiosClient = axios.create({
            baseURL: this.baseURL,
            timeout: 30000
        });
    }

    public async triggerMafiScan(): Promise<AxiosResponse> {
        const params = new URLSearchParams({
            inquiryMode: "distinct",
            pageSize: "10",
            initialTimeOffset: "5000",
            waitTime: "500"
        }).toString();
        const apiResponse = await this.axiosClient.get(`simple-queue/events?${params}`, {
            headers: {
                'Authorization': 'Basic QkxHX0xvZ2lzdGljczpEQ3kxcmVRZWQycXE1ZHJrcHZOOEhpa08=',
                "accept": "application/json"
            }
        });

        console.log('apiRespnse', apiResponse);

        return apiResponse;
    }
}
