import { createTheme, Theme } from "@mui/material";
import { createContext, FC, PropsWithChildren, useContext } from "react";
import { useColorTheme } from "./useColorTheme";

type ThemeContextType = {
    mode: string;
    toggleColorMode: () => void;
    isDarkMode: boolean,
    theme: Theme;
};

export const ThemeContext = createContext<ThemeContextType>({
    mode: "light",
    isDarkMode: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleColorMode: () => {},
    theme: createTheme(),
});

export const ThemeContextProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    const value = useColorTheme();
    return (
        <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    return useContext(ThemeContext);
};
