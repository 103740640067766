import RefreshIcon from "@mui/icons-material/Refresh";
import {DateTime} from "luxon";
import React, {useEffect, useState} from "react";

// MaterialUI
import {Box, Button, Divider, Grid, IconButton, Theme, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import WetDialogComponent from "../components/dialogs/wetDialog.component";
import AttachButtonComponent from "../components/mafi/attachButton.component";
import {LiveMafiLocationComponent, MafiInformation} from "@blg/blg-core";

// Components
import MafiDetailsComponent from "../components/mafi/mafiDetails.component";
import MapComponent from "../components/map/map.component";
import {useAppSelector} from "../store/hooks";
import TugService from "@blg/blg-core/lib/esm/services/roTrail/roTrailTugService";
import {store} from "../store/store";
import {setSelectedMafi} from "../store/slices/mafi";
import {setActiveTutorial, setShowLoading} from "../store/slices/global";
import {setLastGridSettings} from "../store/slices/driver";
import MafiLoadingConditionComponent from "../components/mafi/mafiLoadingCondition.component";
import {coupleMafi, decoupleMafi, getUpdateMafiNo} from "../misc/mafiHelper";
import {SnackbarUtil, MafiNotation} from "@blg/blg-core";
import RfidEventSelectionDialog from "../components/mafi/rfidEventSelectionDialog.component";
import {RFIDEvent} from "../interfaces/RFID/RFIDResponse";
import MafiDecoupledDialogComponent from "../components/mafi/mafiDecoupledDialog.component";
import {useThemeContext} from "../theme/themeContextProvider";
import MafiSearchHelper from "../utils/mafiSearchHelper";

export const defaultContentStyle = (theme: Theme) => {
    return {
        contentBox: {
            display: "flex", flexDirection: 'column', backgroundColor: theme.palette.background.paper,
            width: "100%", borderRadius: '12px', position: "relative",
        },
        mapBox: {
            height: "100%", position: "relative", backgroundColor: theme.palette.background.paper,
            width: "100%", borderRadius: '12px'
        },
        titleStyle: {
            color: "black",
            fontWeight: "500",
            fontSize: 24,
            textAlign: "center",
            mb: 1
        },
        iconStyle: {
            border: "1px solid #3669A8",
            backgroundColor: "#FFF",
            width: "44px",
            height: "44px"
        },
        loadingConditionButton: {
            width: '100%',
            py: 1,
            borderRadius: '12px',
            textTransform: 'none',
            fontSize: 24
        },
        stackElement: {
            marginTop: 10,
            display: "flex",
            alignItems: "center",
            minHeight: 50,
            borderRadius: '12px',
            fontSize: 24
        }
    }
}

const BypassDetailsView: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {isDarkMode} = useThemeContext();

    const lastGridSettings = useAppSelector(state => state.driver).lastGridSettings;
    const isAttached = useAppSelector(state => state.mafi).isAttached;
    const attachedMafi = useAppSelector(state => state.mafi).attachedMafi;

    const driverLatitude = useAppSelector(state => state.driver).latitude;
    const driverLongitude = useAppSelector(state => state.driver).longitude;

    const [showMafiSelection, setShowMafiSelection] = useState(false);

    const [mafiGridSize, setMafiGridSize] = useState(4);
    const [mapGridSize, setMapGridSize] = useState(4);
    const [mafiInfoGridSize, setMafiInfoGridSize] = useState(4);

    const [notation, setNotation] = useState<MafiNotation | null>(null);
    const [locationValid, setLocationValid] = useState(false);

    const [showMap, setShowMap] = useState(true);
    const [showMafiDetails, setShowMafiDetails] = useState(true);

    const [decoupleDialog, setDecoupleDialog] = useState<{ show: boolean, mafi?: MafiInformation }>({
        show: false,
        mafi: undefined
    });

    const [showWetDialog, setShowWetDialog] = useState(false);

    const lastMafiSync = useAppSelector(state => state.mafi).lastMafiSync;
    let dateString = "-";
    if (lastMafiSync) {
        const dateObject = DateTime.fromISO(lastMafiSync);
        dateString = dateObject.toFormat("dd.MM.yyyy HH:mm:ss")
    }

    useEffect(() => {
        if (!isAttached && attachedMafi) {
            store.dispatch(setSelectedMafi(undefined));
        }
    }, []);

    useEffect(() => {
        if (!attachedMafi) {
            store.dispatch(setActiveTutorial("COUPLE"));
            setMafiGridSize(0);
            setMafiInfoGridSize(6);
            setMapGridSize(6);
            setShowMap(true);
            setShowMafiDetails(false);
        } else {
            if (attachedMafi.stack.length > 0) {
                store.dispatch(setActiveTutorial("DECOUPLE_STACK"));
            } else {
                store.dispatch(setActiveTutorial("DECOUPLE"));
            }

            if (lastGridSettings) {
                setMafiGridSize(lastGridSettings.mafi);
                setMafiInfoGridSize(lastGridSettings.mafiInfo);
                setMapGridSize(lastGridSettings.map);
                setShowMap(lastGridSettings.map > 1);
                setShowMafiDetails(lastGridSettings.mafi > 1);
            } else {
                setMafiGridSize(4);
                setMafiInfoGridSize(4);
                setMapGridSize(4);
                setShowMafiDetails(true);
            }
        }
    }, [attachedMafi]);

    /**
     * is called when the attach / detach button is used
     */
    async function attach(confirmed: boolean) {
        if (isAttached && attachedMafi) {
            if (!confirmed && attachedMafi!._hasWetSensitiveData && !notation!.wetSensitive) {
                setShowWetDialog(true);
                return;
            }

            try {
                store.dispatch(setShowLoading({showLoading: true}));

                const mafiNo = getUpdateMafiNo(attachedMafi);

                // MafiStatus 609 is "loaded". The mafi location should not be updated for >= 609
                if (attachedMafi.mafiStatus < 609) {
                    await TugService.instance.setMafiLocation({
                        mafiNo: mafiNo,
                        location: notation!.notation,
                        lat: driverLatitude!,
                        long: driverLongitude!,
                        handleExternalStock: true
                    });
                }

                if (attachedMafi.loadStatus) {
                    await TugService.instance.setMafiLoadStatus({
                        mafiNo: mafiNo,
                        loadStatus: attachedMafi.loadStatus
                    });
                }

                const mafiCopy = MafiInformation.parseFromObject({...attachedMafi});
                mafiCopy.mafiNotation = {
                    text: "",
                    notation: notation!.notation,
                    position: {lat: 0, long: 0},
                    type: "ROW"
                };

                await decoupleMafi(attachedMafi);
                setDecoupleDialog({show: true, mafi: mafiCopy});

                setTimeout(() => {
                    setDecoupleDialog({show: false, mafi: undefined});
                }, 3000);

                store.dispatch(setSelectedMafi(undefined));
            } catch (e) {
                showApiError(e);
            } finally {
                store.dispatch(setShowLoading({showLoading: false}));
            }
        } else {
            setShowMafiSelection(true);
        }
    }

    const showApiError = (e: any) => {
        if (e.response && e.response.data && e.response.data.message && e.response.data.message !== "Bad Request") {
            SnackbarUtil.error(e.response.data.message);
        } else {
            SnackbarUtil.error(t('ERROR.UNKNOWN_ERROR'));
        }
    }

    function toggleMafiDetails() {
        setShowMafiDetails(!showMafiDetails);
        calculateGridSizes(showMap, !showMafiDetails);
    }

    function toggleMapDetails() {
        setShowMap(!showMap);
        calculateGridSizes(!showMap, showMafiDetails);
    }

    function calculateGridSizes(mapState: boolean, mafiState: boolean) {
        if (mafiState) {
            setMapGridSize(mapState ? 4 : 1);
            setMafiInfoGridSize(mapState ? 4 : 5);
            setMafiGridSize(mapState ? 4 : 6);
            store.dispatch(setLastGridSettings({
                map: mapState ? 4 : 1,
                mafi: mapState ? 4 : 6,
                mafiInfo: mapState ? 4 : 5
            }));
        } else {
            setMapGridSize(mapState ? 6 : 1);
            if (!isAttached) {
                setMafiGridSize(0);
                setShowMafiDetails(false);
                setMafiInfoGridSize(mapState ? 6 : 11);
            } else {
                setMafiInfoGridSize(mapState ? 5 : 10);
                setMafiGridSize(1);

                store.dispatch(setLastGridSettings({
                    map: mapState ? 6 : 1,
                    mafi: 1,
                    mafiInfo: mapState ? 5 : 10
                }));
            }
        }
    }

    const loadMafiInfo = async (rfidTag: RFIDEvent) => {
        try {
            const mafiResponse = await TugService.instance.getByMafiNo(rfidTag.epc);
            const selectedMafi = MafiInformation.parseFromObject(mafiResponse.data);

            if (selectedMafi.stackNo) {
                const apiResponse = await TugService.instance.stackByMafiNo(selectedMafi.mafiNo);
                const stackMafis = MafiInformation.parseFromArray(apiResponse.data) as MafiInformation[];

                const activeMafi = stackMafis.find(m => m.mafiNo === selectedMafi.mafiNo);
                activeMafi!.stack = stackMafis.map(m => m.mafiNo);
                await coupleMafi(activeMafi!);
            } else {
                const apiResponse = await TugService.instance.allByMafiNo(selectedMafi.mafiNo);
                const activeMafi = MafiInformation.parseFromObject(apiResponse.data[0]);
                await coupleMafi(activeMafi!);
            }
        } catch (e) {
            SnackbarUtil.error(`Mafi mit der Mafinummer ${rfidTag.epc} wurde nicht im Bestand gefunden`);
        }
    }

    const onNotationChanged = (isValid: boolean, value?: MafiNotation) => {
        if (value) {
            setNotation(value);
        }
        setLocationValid(isValid);
    }

    return (
        <Box sx={{
            height: "100%",
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.surface.main
        }}>
            <Grid container columnSpacing={3} sx={{p: 3, height: '100%'}}>
                <Grid item xs={mapGridSize} sx={{height: '100%'}}>
                    <Box sx={{width: "100%", height: "100%"}}>
                        {
                            <MapComponent showCollapseIcons={attachedMafi !== null}
                                          collapseEvent={() => toggleMapDetails}
                                          componentSize={mapGridSize}
                                          isCollapsed={!showMap} mafiInformation={attachedMafi!}></MapComponent>

                        }
                    </Box>
                </Grid>
                {/* left row that shows the mafi number and allows to attach/detach the mafi */}
                <Grid item xs={mafiInfoGridSize} sx={{height: '100%'}}>
                    <Box sx={{...defaultContentStyle(theme).contentBox, p: 4, height: '100%'}}>
                        <Box sx={{
                            border: '1px solid #D1E0F9',
                            py: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '12px'
                        }}>
                            {attachedMafi &&
                                <Typography sx={{fontWeight: 400, fontSize: 24}}>{attachedMafi.mafiNo}</Typography>
                            }
                            {!attachedMafi &&
                                <Typography sx={{fontWeight: 400, fontSize: 24, textAlign: "center", px: 2}}>
                                    {t("TUG_ORDER_DETAIL.USE_SCANNER_HINT")}
                                </Typography>
                            }
                        </Box>
                        <Box
                            sx={{mt: 3, width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <AttachButtonComponent isAttached={isAttached}
                                                   isDisabled={isAttached && !locationValid}
                                                   attachEvent={() => attach(false)}/>
                            {attachedMafi && isAttached &&
                                <Button
                                    className={isDarkMode ? "attach-button" : ""}
                                    variant={"contained"}
                                    sx={{
                                        backgroundColor: `${isDarkMode ? "rgba(251, 251,252, 0.15)" : theme.palette.primary.main}`,
                                        textTransform: 'none', borderRadius: '12px', width: "100%", mt: 2
                                    }}
                                    onClick={async () => {
                                        const mafiNo = attachedMafi.mafiNo;
                                        await decoupleMafi(attachedMafi!);
                                        SnackbarUtil.success(t("DECOUPLE_DIALOG.SUCCESS", {mafi: mafiNo}));
                                    }}>
                                    <Typography sx={{fontSize: 24}} color={theme.palette.white.main}>
                                        ohne Location abkoppeln
                                    </Typography>
                                </Button>
                            }
                        </Box>
                        <Divider sx={{my: 2, borderColor: theme.palette.greyCustom.main}}/>
                        {!isAttached &&
                            <Box sx={{mb: 1, display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                                <Typography sx={{mr: 1}}>{t("GENERAL.LAST_SYNC")}: {dateString}</Typography>
                                <IconButton onClick={async () => {
                                    await MafiSearchHelper.instance.syncOfflineData({
                                        showLoadingAnimation: true,
                                        hideLoadingText: false
                                    });
                                }}>
                                    <RefreshIcon/>
                                </IconButton>
                            </Box>
                        }
                        {attachedMafi && isAttached &&
                            <Box sx={defaultContentStyle(theme).contentBox}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: "100%"
                                }}>
                                    <Typography sx={defaultContentStyle(theme).titleStyle}>
                                        {t('TUG_ORDER_DETAIL.LOADING_CONDITION.STATUS')}
                                    </Typography>
                                    <MafiLoadingConditionComponent mafi={attachedMafi}/>
                                </Box>
                                <Box sx={{width: "100%", mt: 2}}>
                                    <Typography sx={defaultContentStyle(theme).titleStyle}>
                                        {t('TUG_ORDER_DETAIL.LOCATION')}
                                    </Typography>
                                    <LiveMafiLocationComponent
                                        dangerousGood={attachedMafi._hasDangerousData}
                                        latitude={driverLatitude}
                                        longitude={driverLongitude}
                                        onNotationChangedCallback={onNotationChanged}
                                    />
                                </Box>
                            </Box>
                        }
                    </Box>
                </Grid>
                {/* mafi details, shows the different details of a mafi (e.g. payload weight, length etc.) */}
                {mafiGridSize !== 0 &&
                    <Grid item xs={mafiGridSize} sx={{height: '100%'}}>
                        <Box sx={{width: "100%", height: "100%"}}>
                            <MafiDetailsComponent
                                information={attachedMafi!}
                                collapseEvent={() => toggleMafiDetails}
                                locationIsValid={locationValid}
                                showCollapseIcons={attachedMafi !== null}
                                componentSize={mafiGridSize}
                                isCollapsed={!showMafiDetails}/>
                        </Box>
                    </Grid>

                }
            </Grid>
            {showMafiSelection &&
                <RfidEventSelectionDialog
                    showDialog={showMafiSelection}
                    selectEvent={async (info) => {
                        await loadMafiInfo(info);
                        setShowMafiSelection(false);
                    }
                    }
                    closeEvent={() => {
                        setShowMafiSelection(false);
                    }}/>
            }
            <MafiDecoupledDialogComponent
                open={decoupleDialog.show}
                mafi={decoupleDialog.mafi!}
                onCloseDialog={() => {
                    setDecoupleDialog({show: false, mafi: undefined});
                }}></MafiDecoupledDialogComponent>
            <WetDialogComponent showDialog={showWetDialog} closeDialog={async (confirmed: boolean) => {
                setShowWetDialog(false);
                if (confirmed) {
                    await attach(confirmed);
                }

            }}></WetDialogComponent>
        </Box>
    );
};

export default BypassDetailsView;
