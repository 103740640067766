import {useTheme} from "@mui/material/styles";
import React, {useState} from "react";
import {Button, Grid} from "@mui/material";
import { MafiInformation } from "@blg/blg-core";
import {useTranslation} from "react-i18next";
import {defaultContentStyle} from "../../views/bypassDetails.view";

interface MafiLoadingConditionProps {
    mafi: MafiInformation
}

const MafiLoadingConditionComponent = ({mafi}: MafiLoadingConditionProps) => {
    const {t} = useTranslation();
    const theme = useTheme();

    const [isLoaded, setIsLoaded] = useState(mafi.loadStatus === "VOLL");

    return (
        <Grid container spacing={2} sx={{display: "flex", alignItems: "center", backgroundColor: theme.palette.background.paper}}>
            <Grid item xs={6}>
                <Button variant={isLoaded ? "contained" : "outlined"}
                        disabled={mafi.stack && mafi.stack.length > 0}
                        onClick={() => {
                          mafi.loadStatus = "VOLL";
                          setIsLoaded(true);
                        }}
                        sx={defaultContentStyle(theme).loadingConditionButton}>
                    {t('TUG_ORDER_DETAIL.LOADING_CONDITION.FULL')}
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button variant={isLoaded ? "outlined" : "contained"}
                        disabled={mafi.stack && mafi.stack.length > 0}
                        onClick={() => {
                            mafi.loadStatus = "LEER";
                            setIsLoaded(false);
                        }}
                        sx={defaultContentStyle(theme).loadingConditionButton}>
                    {t('TUG_ORDER_DETAIL.LOADING_CONDITION.EMPTY')}
                </Button>
            </Grid>
        </Grid>
    )
}

export default MafiLoadingConditionComponent;
