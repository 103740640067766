import React from "react";
import {Box} from "@mui/material";
import {OrderShipTab} from "../../enum/orderShipTab.enum";
import {OrderTab} from "../../enum/orderTab.enum";

export interface TabPanelProps {
    children?: React.ReactNode;
    tab: OrderShipTab | OrderTab,
    activeTab: OrderShipTab | OrderTab
}
const TabPanelComponent = (props: TabPanelProps) => {
    const { children, tab, activeTab, ...other } = props;

    return (
        <div
            style={{ height: "100%" }}
            role="tabpanel"
            hidden={activeTab !== tab}
            {...other}
        >
            {activeTab === tab && (
                <Box sx={{ height: "100%" }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default TabPanelComponent;
