import React, {useState} from "react";

// MaterialUI
import {Box, Tab, Tabs, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import BypassLoadShipView from "./bypassLoadShip.view";
import {AppColors} from "../../styles/app.colors";
import TabPanelComponent from "../../components/navigation/tabPanel.component";
import {OrderShipTab} from "../../enum/orderShipTab.enum";
import ByPassDeleteShip from "./bypassDeleteShip.view";

/**
 * tab view to select between the different subTasks, currently three subTasks are supported
 * @constructor
 */
const OrderOverview: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState<OrderShipTab>(OrderShipTab.DELETE_BYPASS_SHIP);

    /**
     * updates the active tab when a tab was tapped
     * @param event
     * @param newValue
     */
    function onTabChanged(event: React.SyntheticEvent, newValue: OrderShipTab) {
        setActiveTab(newValue);
    }

    /**
     * checks if the tab is active
     * @param tab
     */
    function isActive(tab: OrderShipTab | string): boolean {
        const currentTabIndex = OrderShipTab[tab as number];
        return currentTabIndex.toString() === activeTab.toString();
    }

    return (
        <Box sx={{height: "100%", display: 'flex', flexDirection: 'column'}}>
            <Box sx={{width: "100%", display: "flex", backgroundColor: theme.palette.primary.main, px: 3}}>
                <Tabs value={activeTab} onChange={onTabChanged}>
                    <Box
                        sx={{backgroundColor: AppColors.tab, mr: '10px', borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px', px: '20px', py: '18px'}}
                    ><Typography sx={{color: theme.palette.white.main, fontWeight: 500}}>GANG</Typography></Box>
                    {Object.values(OrderShipTab).filter((t) => isNaN(Number(t))).map((tab) =>
                        <Tab
                            label={t(`ORDER_TABLE.TABS.${tab}`)}
                            key={tab}
                            sx={{backgroundColor: isActive(tab) ? 'white': AppColors.tab,
                                mr: '10px', borderTopLeftRadius: '4px', textTransform: 'none',
                                borderTopRightRadius: '4px', fontWeight: 500,
                                color: isActive(tab) ? AppColors.tab: 'white'
                            }}
                        />)}
                </Tabs>
            </Box>
            <Box>
                {/* bypass delete ship view*/}
                <TabPanelComponent tab={OrderShipTab.DELETE_BYPASS_SHIP} activeTab={activeTab}>
                    <ByPassDeleteShip />
                </TabPanelComponent>

                {/* bypass load on ship view */}
                <TabPanelComponent tab={OrderShipTab.BYPASS_LOAD_SHIP} activeTab={activeTab}>
                    <BypassLoadShipView />
                </TabPanelComponent>
            </Box>
        </Box>
    );
};

export default OrderOverview;
