import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface GridSizeInterface {
    map: number,
    mafiInfo: number,
    mafi: number
}

export interface DriverState {
    latitude?: number,
    longitude?: number,
    accuracy?: number,
    lastGridSettings?: GridSizeInterface
}

const initialState: DriverState = {latitude: undefined, longitude: undefined, lastGridSettings: undefined};

const driverState = createSlice({
    name: 'driver',
    initialState,
    reducers: {
        setDriverPosition(state: DriverState, action: PayloadAction<{ latitude: number, longitude: number, accuracy: number }>) {
            state.latitude = action.payload.latitude;
            state.longitude = action.payload.longitude
            state.accuracy = action.payload.accuracy;
        },
        setLastGridSettings(state: DriverState, action: PayloadAction<GridSizeInterface>) {
            state.lastGridSettings = action.payload;
        },
    },
});

export const {setDriverPosition, setLastGridSettings} = driverState.actions;
export default driverState.reducer;
