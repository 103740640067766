import React from 'react';
import {ThemeProvider} from "@mui/material/styles";
import {Provider} from "react-redux";
import {persistor, store} from "./store/store";
import {PersistGate} from "redux-persist/integration/react";
import {HashRouter, Route, Routes} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import {LicenseInfo} from '@mui/x-license-pro';

import {
    SnackbarUtilsConfigurator
} from "@blg/blg-core/lib/esm/components";
import {useThemeContext} from "./theme/themeContextProvider";

import HomeView from "./views/home.view";
import {ROUTES} from "./routes/routes";
import LoginView from "./views/login.view";
import DefaultView from "./views/default.view";
import TaskSelectionView from "./views/taskSelection.view";
import MafiSearchView from "./views/mafiSearch/mafiSearch.view";

import markerIcon from "../node_modules/leaflet/dist/images/marker-icon.png";
import leaflet from 'leaflet';
import OrderOverview from "./views/bypassShip/bypassShipOverview.view";
import BypassTerminalOverviewView from "./views/bypassTerminal/bypassTerminalOverview.view";
import MafiDetailsView from "./views/mafiSearch/mafiDetails.view";
import * as localforage from "localforage";
import {setIsOnline} from "./store/slices/global";
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import UnStackDetailView from "./views/unstack/unStackDetail.view";

declare global {
    interface Window {
        cordova: any;
        api: any;
    }
    interface Navigator {
        app: any;
        device: any;
    }
}

function App() {
    const { theme } = useThemeContext();

    // set up localforage
    localforage.config({
        name: 'tug-app',
        version: 1.0,
        storeName: 'rotrail',
        description: 'RoTrail tug app'
    });

    leaflet.Marker.prototype.setIcon(leaflet.icon({
        iconUrl: markerIcon
    }));

    document.addEventListener("offline", () => {
        console.log('App is offline!!');
        store.dispatch(setIsOnline(false));
    }, false);
    document.addEventListener("online", () => {
        console.log('App is online!!');
        store.dispatch(setIsOnline(true));
    }, false);

    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY!);

    return (
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3}
                                  hideIconVariant={false}
                                  anchorOrigin={{horizontal: "center", vertical: "bottom"}}>
                    <SnackbarUtilsConfigurator/>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <HashRouter>
                                <Routes>
                                    <Route path={ROUTES.ROOT} element={<DefaultView/>}>
                                        <Route path={ROUTES.LOGIN} element={<LoginView/>}/>
                                        <Route path={ROUTES.HOME} element={<HomeView/>}>
                                            <Route index element={<TaskSelectionView/>}/>
                                            <Route path={ROUTES.TASK_SELECTION} element={<TaskSelectionView/>}/>
                                            <Route path={ROUTES.MAFI_SEARCH} element={<MafiSearchView/>}/>
                                            <Route path={ROUTES.MAFI_DETAILS} element={<MafiDetailsView/>}/>
                                            <Route path={ROUTES.ORDER_OVERVIEW} element={<OrderOverview/>}/>
                                            <Route path={ROUTES.BYPASS_TERMINAL}
                                                   element={<BypassTerminalOverviewView/>}/>
                                            <Route path={ROUTES.UNSTACK_DETAILS} element={<UnStackDetailView />} />
                                        </Route>
                                    </Route>
                                </Routes>
                            </HashRouter>
                        </PersistGate>
                    </Provider>
                </SnackbarProvider>
            </ThemeProvider>
        </I18nextProvider>
    );
}

export default App;
