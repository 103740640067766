import React, {useEffect} from "react";

// MaterialUI
import {Box} from "@mui/material";
import BypassDetailsView from "../bypassDetails.view";
import {setActiveTutorial} from "../../store/slices/global";
import {store} from "../../store/store";

/**
 * bypass a mafi without order of the system (e.g. to clean up)
 * @constructor
 */
const BypassTerminalOverviewView: React.FC = () => {
    useEffect(() => {
        store.dispatch(setActiveTutorial("COUPLE"));
    }, []);

    return (
        <Box sx={{display: "flex", height: "100%", flexDirection: 'column'}}>
            <Box sx={{height: "100%", maxHeight: "calc(100vh - (64px))"}}>
                <BypassDetailsView />
            </Box>
        </Box>
    );
};

export default BypassTerminalOverviewView;
