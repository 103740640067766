import {MafiCategory} from "@blg/blg-core";
import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import FilterElementComponent from "./filterElement.component";

interface CategoryFilterContainerProps {
    mafiCategories: MafiCategory[],
    currentSelectedCategory: MafiCategory[],
    onCategoryChanged: (category: MafiCategory[]) => void
}

const CategoryFilterContainerComponent = (props: CategoryFilterContainerProps) => {
    const {t} = useTranslation();

    function onCurrentCategoryChanged(categoryId: number) {
        const selectedCategory = props.mafiCategories.find(c => c.id === categoryId);
        if (props.currentSelectedCategory.find(t => t.id === selectedCategory!.id) === undefined) {
            props.onCategoryChanged([...props.currentSelectedCategory, selectedCategory!]);
        } else {
            props.onCategoryChanged(props.currentSelectedCategory.filter(c => c.id !== selectedCategory!.id));
        }
    }

    const allFilterElements = props.mafiCategories.map(category =>
        <FilterElementComponent
            text={category.category}
            value={category.id}
            isSelected={props.currentSelectedCategory.find(c => c.id === category.id) !== undefined}
            key={category.id}
            onClick={onCurrentCategoryChanged}/>);

    return <Box display={'flex'} flexDirection={'column'}>
        <Typography sx={{ textDecoration: 'underline' }} variant={"h6"}>
            {t('TASK_SELECTION.MAFI_SEARCH.FILTER_DIALOG.CATEGORY')}
        </Typography>
        {allFilterElements}
    </Box>;
}

export default CategoryFilterContainerComponent;
