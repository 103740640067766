import {
    Components,
    createTheme,
    PaletteColor,
    PaletteColorOptions,
    Theme
} from '@mui/material/styles';
import {deDE as coreDeDE} from '@mui/material/locale';
import {deDE} from '@mui/x-data-grid';
import {blgThemeColors} from '@blg/blg-core/lib/esm/components/theme/parts/colors';
import {blgThemeTypography} from '@blg/blg-core/lib/esm/components/theme/parts/typography';
import {blgThemeComponents} from '@blg/blg-core/lib/esm/components/theme/parts/components';
import {BlgDefaultPalette} from '@blg/blg-core/lib/esm/interfaces/BlgDefaultPalette.interface';

const {palette} = createTheme();
const {augmentColor} = palette;
const createColor = (mainColor: string) => augmentColor({color: {main: mainColor}});

declare module '@mui/material/styles' {
    interface CustomPalette extends BlgDefaultPalette {
        surface: PaletteColorOptions;
        yellowText: PaletteColorOptions;
        white: PaletteColorOptions;
    }

    // eslint-disable-next-line
    interface Palette extends CustomPalette {
    }

    // eslint-disable-next-line
    interface PaletteOptions extends CustomPalette {
    }

    interface Theme {
        palette: { [key in keyof CustomPalette]: PaletteColor } & Palette,
    }
}

declare module "@mui/material/IconButton" {
    interface IconButtonPropsColorOverrides extends BlgDefaultPalette {
        white: true
    }
}

const extendedBlgThemeColors = {
    ...blgThemeColors,
    surface: blgThemeColors.blueLight,
    yellowText: createColor("#FFD600"),
    white: createColor("#FFF"),
    lockedMafiColor: createColor("#FFF"),
};

const darkThemeColors = {
    ...blgThemeColors,
    primary: createColor('#1C1D22'),
    background: {
        paper: createColor('#1C1D22').main,
        default: createColor('#323232').main,
    },
    yellowText: createColor("#FFD600"),
    surface: createColor("#000"),
    lockedMafiColor: createColor("#FFF"),
    text: {
        primary: createColor('#FBFBFC').main,
        secondary: createColor('#FBFBFC').main,
        disabled: createColor('#FBFBFC').main
    },
};

export const theme = createTheme({
    palette: extendedBlgThemeColors,
    typography: blgThemeTypography,
    components: blgThemeComponents as Components<Omit<Theme, 'components'>>,
}, deDE, coreDeDE);

export const darkTheme = createTheme({
    palette: darkThemeColors,
    typography: {
        allVariants: {
            fontFamily: '\'Inter\', sans-serif',
            color: createColor("#FFF").main
        }
    },
    components: {
        ...blgThemeComponents as Components<Omit<Theme, 'components'>>,
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(251, 251,252, 0.15) !important",
                    "input": {
                        color: "#FFF !important"
                    },
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(251, 251,252, 0.15) !important",
                    "& .MuiSvgIcon-root": {
                        color: "#FFF !important"
                    }
                },
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    color: `${theme.palette.white.main} !important`
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderColor: `${theme.palette.greyCustomLight.main} !important`,
                    color: `${theme.palette.white.main} !important`,
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        borderColor: `${theme.palette.grey.A700} !important`,
                        color: `${theme.palette.grey.A700} !important`
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        opacity: 1
                    }
                }
            }
        }
    }
}, deDE, coreDeDE);
