import L from 'leaflet';

const mafiMarker = new L.Icon({
    iconUrl: require('../../../assets/images/MafiMarker.png'),
    iconRetinaUrl: require('../../../assets/images/MafiMarker.png'),
    iconSize: [56, 70],
    iconAnchor: [28, 70],
    popupAnchor: [0, -70],
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
    className: 'leaflet-div-icon'
});

const tugMafiMarker = new L.Icon({
    iconUrl: require('../../../assets/images/TugMafiMarker.png'),
    iconRetinaUrl: require('../../../assets/images/TugMafiMarker.png'),
    iconSize: [56, 70],
    iconAnchor: [28.5, 70],
    popupAnchor: [0, -70],
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
    className: 'leaflet-div-icon'
});

const driverMarker = new L.Icon({
    iconUrl: require('../../../assets/images/TugMarker.png'),
    iconRetinaUrl: require('../../../assets/images/TugMarker.png'),
    iconSize: [75, 94],
    iconAnchor: [37.5, 94],
    popupAnchor: [0, -94],
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
    className: 'leaflet-div-icon'
});

export {mafiMarker, driverMarker, tugMafiMarker};
