import React from 'react';
import {GridRenderCellParams} from "@mui/x-data-grid-premium";
import {Box, Typography} from '@mui/material';
import { MafiInformation } from "@blg/blg-core";

interface StackGridTreeDataGroupingCellProps {
    mafi?: MafiInformation,
    innerRef: any
}

const StackGridTreeDataGroupingCell = (props: GridRenderCellParams & StackGridTreeDataGroupingCellProps) => {
    const {id, field, rowNode, mafi} = props;

    const handleClick = (event: any) => {
        props.innerRef.current.setRowChildrenExpansion(id, !(rowNode as any).childrenExpanded);
        props.innerRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    return (
        <Box sx={{ml: 1}} onClick={handleClick}>
            {mafi?.stackNo ? (
                <Typography variant={"body1"}>
                    Stack {mafi?.stackNo}
                </Typography>
            ) : (
                <span/>
            )}
        </Box>
    );
};

export default StackGridTreeDataGroupingCell;
