import React from "react";
import {Box, Typography} from "@mui/material";

/**
 * deletes a bypassing for a ship
 * @constructor
 */
const ByPassDeleteShip: React.FC = () => {
    return (
        <Box sx={{height: "100%", display: 'flex', flexDirection: 'column'}}>
            <Typography>Hier ensteht bald Umfuhr Schiff löschen </Typography>
        </Box>
    );
};

export default ByPassDeleteShip;
