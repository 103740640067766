import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import FilterElementComponent from "./filterElement.component";

interface PoolMafiFilterContainerProps {
    poolMafiFilter: boolean,
    onPoolFilterChanged: (poolMafiFilter: boolean) => void
}

const PoolMafiFilterContainerComponent = (props: PoolMafiFilterContainerProps) => {
    const {t} = useTranslation();

    function onPoolMafiFilterChanged() {
        props.onPoolFilterChanged(!props.poolMafiFilter);
    }

    return <Box display={'flex'} flexDirection={'column'}>
        <Typography sx={{ textDecoration: 'underline' }} variant={"h6"}>
            {t('TASK_SELECTION.MAFI_SEARCH.FILTER_DIALOG.POOL_MAFI')}
        </Typography>
        <FilterElementComponent
            text={'Pool'}
            value={'pool'}
            isSelected={props.poolMafiFilter}
            key={'pool'}
            onClick={onPoolMafiFilterChanged}/>
    </Box>;
}

export default PoolMafiFilterContainerComponent;
