import {Button, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import React from "react";
import {useTranslation} from "react-i18next";
import {AppColors} from "../../styles/app.colors";
import {useThemeContext} from "../../theme/themeContextProvider";

interface AttachButtonProps {
    isAttached: boolean,
    isDisabled: boolean,
    attachEvent: () => void,
}

const AttachButtonComponent = ({ isAttached, isDisabled, attachEvent }: AttachButtonProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { isDarkMode } = useThemeContext();
    return (
        <Button
            className={isDarkMode ? "attach-button": ""}
            disabled={isDisabled}
            variant={"contained"}
            sx={{
                backgroundColor: isAttached ? theme.palette.red.main : AppColors.success,
                textTransform: 'none', borderRadius: '12px', width: "100%"
            }}
            onClick={attachEvent}>
            <Typography sx={{fontSize: 24}} color={theme.palette.white.main }>
                {isAttached ? t('TUG_ORDER_DETAIL.DETACH') : t('TUG_ORDER_DETAIL.ATTACH')}
            </Typography>
        </Button>
    )
}

export default AttachButtonComponent;
