import React from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { MafiInformation } from "@blg/blg-core";

/**
 * different props that can be passed to the dialog
 */
interface MafiDecoupledDialogProps {
    mafi: MafiInformation,
    open?: boolean,
    onCloseDialog?: () => void
}

const MafiDecoupledDialogComponent = (props: MafiDecoupledDialogProps) => {
    const {t} = useTranslation();

    /**
     * is called when the close button is tapped
     */
    function closeDialog() {
        props.onCloseDialog?.call(null);
    }

    return (<Box>
        {props.mafi &&
            <Dialog
                maxWidth={"lg"}
                onClose={closeDialog}
                open={props.open ?? false}>
                <DialogContent>
                    <Box sx={{display: "flex", flexDirection: "column", width: 500}}>
                        <Box sx={{display: "flex", alignItems: "center", mb: 3}}>
                            <Typography variant={"h5"}>
                                {t("DECOUPLE_DIALOG.SUCCESS", {mafi: props.mafi.mafiNo})}
                            </Typography>
                        </Box>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <Typography variant={"h6"}>
                                {t("DECOUPLE_DIALOG.LOCATION")}: { props.mafi.mafiNotation?.notation }
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"outlined"}
                        startIcon={<CloseIcon/>}
                        onClick={closeDialog}>
                        <Typography variant={"h6"}>
                            {t("GOOD_DIALOG.CLOSE")}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        }
    </Box>);
}

export default MafiDecoupledDialogComponent;
