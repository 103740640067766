import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export interface GlobalState {
    loading: {
        showLoading: boolean,
        loadingText?: string
    },
    activeView: {
        text: string, params: any
    },
    activeTutorial: "LOGIN" | "COUPLE" | "DECOUPLE" | "DECOUPLE_STACK" | "JOB_SELECTION" | "MAFI_SEARCH" | "TEAM_SELECTION",
    isOnline: boolean
}

const initialState: GlobalState = {
    loading: {
        showLoading: false,
        loadingText: undefined
    },
    activeView: {
        text: "", params: null
    },
    activeTutorial: "LOGIN",
    isOnline: true
};

const globalState = createSlice({
    name: "global",
    initialState,
    reducers: {
        setShowLoading(state: GlobalState, action: PayloadAction<{ showLoading: boolean, loadingText?: string }>) {
            state.loading = action.payload;
        },
        setActiveView(state: GlobalState, action: PayloadAction<{
            text: string, params: any
        }>) {
            state.activeView = action.payload;
        },
        setIsOnline(state: GlobalState, action: PayloadAction<boolean>) {
            state.isOnline = action.payload;
        },
        setActiveTutorial(state: GlobalState, action: PayloadAction<"LOGIN" | "COUPLE" | "DECOUPLE" | "DECOUPLE_STACK" | "JOB_SELECTION" | "MAFI_SEARCH" | "TEAM_SELECTION">) {
            state.activeTutorial = action.payload;
        }
    },
});

export const {setShowLoading, setActiveView, setIsOnline, setActiveTutorial} = globalState.actions;
export default globalState.reducer;
