import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import FilterElementComponent, {MafiFilterEntry} from "./filterElement.component";

interface TaraFilterContainerProps {
    allTaraEntries: MafiFilterEntry[],
    currentSelectedTara: MafiFilterEntry[],
    onTaraChanged: (length: MafiFilterEntry[]) => void
}

const TaraFilterContainerComponent = (props: TaraFilterContainerProps) => {
    const {t} = useTranslation();

    function onCurrentTaraChanged(tara: number) {
        const selectedTara = props.allTaraEntries.find(l => l.id === tara);
        if (props.currentSelectedTara.find(t => t.id === selectedTara!.id) === undefined) {
            props.onTaraChanged([...props.currentSelectedTara, selectedTara!]);
        } else {
            props.onTaraChanged(props.currentSelectedTara.filter(l => l.id !== selectedTara!.id));
        }
    }

    const allFilterElements = props.allTaraEntries.map(tara =>
        <FilterElementComponent
            text={tara.text}
            value={tara.id}
            isSelected={props.currentSelectedTara.find(t => t.id === tara.id) !== undefined}
            key={tara.id}
            onClick={onCurrentTaraChanged}/>);

    return <Box display={'flex'} flexDirection={'column'}>
        <Typography sx={{ textDecoration: 'underline' }} variant={"h6"}>
            {t('TASK_SELECTION.MAFI_SEARCH.FILTER_DIALOG.TARA')}
        </Typography>
        {allFilterElements}
    </Box>;
}

export default TaraFilterContainerComponent;
