import {combineReducers} from "redux";
import storage from 'redux-persist/lib/storage'
import global from './global';
import auth from './auth';
import teams from './teams';
import userSettings from './userSettings';
import driver from './driver';
import mafi from './mafi';
import searchSettings from './searchSettings';

export const rootReducer = combineReducers({
    global, auth, teams, userSettings, driver, mafi, searchSettings
});

export default (state: any, action: any) => {
    if (action.type === "CLEAR_STORE") {
        storage.removeItem(`persist:${process.env.REACT_APP_STORE_KEY}`);
        return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
}

