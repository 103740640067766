import {useTheme} from "@mui/material/styles";
import React, {useEffect, useState} from "react";

// MaterialUI
import {Box} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import {useTranslation} from "react-i18next";
import {TableColumnInterface} from "../../interfaces/tableColumnInterface";
import {defaultTableStyle} from "../../styles/defaultStyles";
import {TableProps} from "@blg/blg-core/lib/esm/interfaces";
import {Check, Map} from "@material-ui/icons";
import {AppColors} from "../../styles/app.colors";

/**
 * bypasses a mafi on the terminal (from location A to location B)
 * @constructor
 */
const ByPassLoadShipTableComponent = (props: TableProps) => {
    const {t} = useTranslation();
    const theme = useTheme();

    const [columns, setColumns] = useState<TableColumnInterface[]>([]);

    const headers: Array<TableColumnInterface> = [
        { field: 'status', headerName: '', sortable: false, width: 50,  headerClassName: 'cargotable-header',
            renderCell: () => {
                return <Check style={{color: AppColors.success}} />
            }},
        { field: 'mafiNumber', headerName: 'TABLE_HEADERS.MAFI_NUMBER', sortable: false, width: 260, headerClassName: 'cargotable-header'},
        { field: 'location', headerName: 'TABLE_HEADERS.LOCATION_FROM', sortable: false, width: 260,  headerClassName: 'cargotable-header'},
        { field: 'destination', headerName: 'TABLE_HEADERS.DESTINATION', sortable: false, width: 260,  headerClassName: 'cargotable-header'},
        { field: 'all', headerName: 'TABLE_HEADERS.WEIGHT_ALL', sortable: false, width: 260,  headerClassName: 'cargotable-header'},
        { field: 'map', headerName: 'TABLE_HEADERS.MAP', sortable: false, width: 200,  headerClassName: 'cargotable-centeredHeader',
            renderCell: () => {
                return <Box sx={{width: '100%', 'display': 'flex', 'justifyContent': 'center'}}><Map /></Box>
            }},
    ];

    /**
     * fills up the columns on initialization
     */
    useEffect(() => {
        if(props.tableRows.length <= 0) return;
        headers.forEach(info => info.headerName = t(info.headerName));
        setColumns(headers);
    }, []);

    /**
     * is called when a row is clicked, tries to call callback function
     * @param row
     */
    function onRowClicked(row: any) {
        props.rowSelected?.call(null, row.row);
    }

    return (
        <DataGridPremium
            disableColumnReorder
            disableColumnPinning
            disableColumnResize
            disableVirtualization
            sx={defaultTableStyle(theme)}
            columnHeaderHeight={40}
            onRowClick={onRowClicked}
            columns={columns}
            rows={props.tableRows}
            autoHeight
        />
    );
};

export default ByPassLoadShipTableComponent;
