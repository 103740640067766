import HelpIcon from "@mui/icons-material/Help";
import React, {ChangeEvent, useEffect, useState} from 'react';
import LoadingImageWhite from "../assets/images/loading-animation-white.svg";
import TutorialDialogComponent from "../components/dialogs/tutorialDialog.component";
import NightModeToggle from "../components/theme/nightModeToggle";
import {setActiveTutorial} from "../store/slices/global";
import {defaultStyles} from "../styles/defaultStyles";
import {Backdrop, Box, Button, Typography} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import LoadingImage from "../assets/images/loading-animation.svg";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {store} from "../store/store";
import {setRefreshToken, setToken, setUsername} from "../store/slices/auth";
import {ROUTES} from "../routes/routes";
import {useThemeContext} from "../theme/themeContextProvider";
import { SnackbarUtil } from '@blg/blg-core';
import AuthService from "@blg/blg-core/lib/esm/services/roTrail/roTrailAuthService";
import MafiSearchHelper from "../utils/mafiSearchHelper";

function Login() {
    const { isDarkMode } = useThemeContext();
    const theme = useTheme();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);

    let form: ValidatorForm;
    const [formInvalid, setFormInvalid] = useState(true);
    const [formData, setFormData] = useState<{ userId: string, password: string }>({
        userId: "", password: ""
    });
    const [showTutorial, setShowTutorial] = useState(false);

    useEffect(() => {
        store.dispatch(setActiveTutorial("LOGIN"));

        if (process.env.REACT_APP_ENV === 'LOCAL') {
            setFormData({
                userId: process.env.REACT_APP_BLG_LOGIN_USER!,
                password: process.env.REACT_APP_BLG_LOGIN_PASSWORD!
            });
        }
    }, []);

    useEffect(() => {
        void checkForm();
    }, [formData.userId, formData.password]);

    async function submitForm() {
        try {
            setShowLoadingAnimation(true);

            const authResponse = await AuthService.instance.login({
                username: formData.userId.trim(),
                password: formData.password,
                grant_type: "password"
            });

            if (authResponse.data.access_token) {
                const accessToken = authResponse.data.access_token;
                const refreshToken = authResponse.data.refresh_token;

                store.dispatch(setToken(accessToken));
                store.dispatch(setRefreshToken(refreshToken));
                store.dispatch(setUsername(formData.userId));

                // Wait for the token to be set
                setTimeout(async () => {
                    // Sync all the notations after the login
                    await MafiSearchHelper.instance.syncOfflineData({ showLoadingAnimation: true, hideLoadingText: false });

                    setShowLoadingAnimation(false);
                    navigate(ROUTES.HOME);
                }, 200);
            }
        } catch (e) {
            console.log('e', e);
            SnackbarUtil.error("LOGIN_VIEW.ERROR");
            setShowLoadingAnimation(false);
        }
    }

    async function checkForm() {
        const isFormValid = await form.isFormValid(true);
        setFormInvalid(!isFormValid);
    }

    async function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const key: string = event.target.name;

        setFormData(prevState => {
            return {
                ...prevState,
                [key]: event.target.value
            };
        });
        await checkForm();
    }

    return (
        <Box sx={{
            backgroundColor: theme.palette.primary.main, height: "100vh",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
        }}>
            <Box sx={{ position: "absolute", right: 15, top: 15 }}>
                <NightModeToggle></NightModeToggle>
            </Box>
            <Typography variant="h3" sx={{fontWeight: 'bold', color: theme.palette.white.main, mb: 3}}>
                {t("LOGIN_VIEW.TITLE")}
            </Typography>
            <Box sx={{ mb: 24 }}>
                <Box sx={{width: "500px", p: 2}} style={defaultStyles(theme).contentBox}>
                    <Typography variant="h6" sx={{mb: 1, textAlign: "center"}}>
                        {t("LOGIN_VIEW.SUBTITLE")}
                    </Typography>
                    <Box sx={{display: "flex", flexDirection: "column", p: 3}}>
                        <ValidatorForm autoComplete="off" onSubmit={submitForm} ref={node => (form = node!)}>
                            <TextValidator
                                sx={{mb: 2}}
                                fullWidth
                                label={t("LOGIN_VIEW.USERID")}
                                onChange={handleChange}
                                id="userId"
                                name="userId"
                                value={formData.userId}
                                validators={["required", "minStringLength:3"]}
                                errorMessages={[t("ERROR.REQUIRED"), t("ERROR.MIN_LENGTH", {min: 3})]}
                                inputProps={{"data-testid": "login-userId"}}
                            />
                            <TextValidator
                                sx={{mb: 2}}
                                fullWidth
                                type="password"
                                label={t("LOGIN_VIEW.PASSWORD")}
                                onChange={handleChange}
                                id="password"
                                name="password"
                                value={formData.password}
                                validators={["required", "minStringLength:3"]}
                                errorMessages={[t("ERROR.REQUIRED"), t("ERROR.MIN_LENGTH", {min: 3})]}
                                inputProps={{"data-testid": "login-password"}}
                            />
                            <Button
                                sx={{mt: 2}}
                                type="submit"
                                disabled={formInvalid}
                                variant="contained"
                                color="primary"
                                size="large"
                                data-testid="login-submit"
                            >{t("GENERAL.NEXT")}</Button>
                        </ValidatorForm>
                    </Box>
                    <Box sx={{position: "relative"}}>
                        <Box  sx={{display: "flex", alignItems: "center", position: "absolute", bottom: -10, right: 0}}>
                            <HelpIcon onClick={() => {
                                setShowTutorial(true);
                            }}  sx={{color: theme.palette.primary.main, mr: 1, pointer: "cursor"}}/>
                            <Typography sx={{opacity: 0.5}}
                                        variant={"caption"}>Version: {process.env.REACT_APP_VERSION}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{position: "absolute", top: 0, left: 0}}>
                <Backdrop
                    sx={{
                        backgroundColor: `${ isDarkMode ? "rgba(36, 26, 5,0.8)": "rgba(222,224,233,0.9)" }`,
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    open={showLoadingAnimation}
                >
                    <object type="image/svg+xml" style={{width: 275, height: 275}}
                            data={isDarkMode ? LoadingImageWhite : LoadingImage}>svg-animation
                    </object>
                </Backdrop>
            </Box>
            <TutorialDialogComponent showDialog={showTutorial} closeEvent={() => setShowTutorial(false)}/>
        </Box>
    );
}

export default Login;
