import React from "react";
import {MafiInformation, UnstackDetailComponent} from '@blg/blg-core'
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import LocalForageHelper from "../../misc/localforageHelper";
import {setShowLoading} from "../../store/slices/global";
import {setAttachedMafi, setSelectedMafi} from "../../store/slices/mafi";
import {store} from "../../store/store";
import MafiSearchHelper from "../../utils/mafiSearchHelper";
import TugService from "@blg/blg-core/lib/esm/services/roTrail/roTrailTugService";

const UnStackDetailView = () => {
    const {mafiNo} = useParams();
    const navigate = useNavigate();

    return <UnstackDetailComponent
        onSaveClicked={async () => {
            try {
                store.dispatch(setShowLoading({showLoading: true}));
                await MafiSearchHelper.instance.syncOfflineData({ showLoadingAnimation: true, hideLoadingText: true });
                const allMafis = await LocalForageHelper.instance.loadAllMafis();
                const activeMafi = allMafis.find(m => m.mafiNo === mafiNo);

                if (activeMafi && activeMafi.stackNo) {
                    const apiResponse = await TugService.instance.stackByMafiNo(activeMafi!.mafiNo);
                    const stackMafis = MafiInformation.parseFromArray(apiResponse.data) as MafiInformation[];

                    const updatedMafi = stackMafis.find(m => m.mafiNo === activeMafi.mafiNo);
                    updatedMafi!.stack = stackMafis.map(m => m.mafiNo);
                    store.dispatch(setAttachedMafi(updatedMafi));
                    store.dispatch(setSelectedMafi(updatedMafi));
                } else {
                    store.dispatch(setAttachedMafi(activeMafi));
                    store.dispatch(setSelectedMafi(activeMafi));
                }

                navigate(-1);
            } catch (e) {

            } finally {
                store.dispatch(setShowLoading({showLoading: false}));
            }
        }}
        navigateToRoute={() => {
            navigate(-1);
        }}
        setShowLoading={(loading) => store.dispatch(setShowLoading({showLoading: loading}))}/>
}

export default UnStackDetailView;
