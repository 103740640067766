import React, {useEffect, useState} from "react";
import {Box, FormControl, IconButton, InputAdornment, InputLabel, Select, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import SearchIcon from '@mui/icons-material/Search';
import {useTheme} from "@mui/material/styles";
import {Refresh} from "@material-ui/icons";
import BypassLoadShipTableComponent from "../../components/table/bypassLoadShipTable.component";
import {ROUTES} from "../../routes/routes";
import {useNavigate} from "react-router-dom";

/**
 * bypasses mafi / load onto a ship
 * @constructor
 */
const BypassLoadShipView: React.FC = () => {
  const {t} = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [orders] = useState<any[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    const filtered = orders.filter((o) => o.filter(searchText));
    setFilteredOrders(filtered);
  }, [searchText]);

  /**
   * callback function, used when row is tapped, opens bypass
   * details
   */
  function onRowSelected() {
    navigate(ROUTES.BYPASS_DETAILS);
  }

  return (
    <Box sx={{height: "100%", display: 'flex', flexDirection: 'column', p: 3}}>
      <Box sx={{
        width: "100%", display: "flex", justifyContent: "space-between", alignItems: 'center',
        backgroundColor: theme.palette.primary.main, p: 3
      }}>
        <Box sx={{width: "30%"}}>
          <TextField
              label={t('GENERAL.SEARCH')}
              variant={"filled"}
              sx={{minWidth: "100%", backgroundColor: theme.palette.white.main, borderRadius: "6px"}}
              value={searchText}
              onChange={event => setSearchText(event.target.value)}
              InputProps={{
                sx: ({color: theme.palette.text.primary}),
                startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color={"primary"}/>
                    </InputAdornment>
                ),
              }}>
          </TextField>
        </Box>
        <Box sx={{width: "30%", backgroundColor: 'white', borderRadius: "6px", height: "56px"}}>
          <FormControl variant={"filled"} sx={{width: '100%'}}>
            <InputLabel id={"select-ship-label"}>{t('GENERAL.SELECT_SHIP')}</InputLabel>
            <Select
                labelId={"select-ship-label"}
            />
          </FormControl>
        </Box>
        <Box sx={{width: "30%", backgroundColor: 'white', borderRadius: "6px", height: "56px"}}>
          <FormControl variant={"filled"} sx={{width: '100%'}}>
            <InputLabel id={"location-label"}>{t('GENERAL.DESTINATION_LOCATION')}</InputLabel>
            <Select
                labelId={"location-label"}
            />
          </FormControl>
        </Box>
        <Box>
          <IconButton sx={{color: 'white'}}>
            <Refresh />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{height: '100%'}}>
        <BypassLoadShipTableComponent
          tableRows={filteredOrders}
          rowSelected={onRowSelected}
        />
      </Box>
    </Box>
  );
};

export default BypassLoadShipView;
