import {useTheme} from "@mui/material/styles";
import React, {useEffect, useState} from "react";

import {Box, IconButton, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

import {MapContainer, TileLayer, Marker, Popup, AttributionControl} from 'react-leaflet';
import {latLngBounds, Map} from 'leaflet';
import {useThemeContext} from "../../theme/themeContextProvider";
import {defaultContentStyle} from "../../views/bypassDetails.view";
import {mafiMarker, driverMarker, tugMafiMarker} from './markers/mapMarkers';
import {CollapseComponentProps} from "../../interfaces/collapseComponentInterface";
import CloseIcon from "@mui/icons-material/Close";
import MapIcon from "@material-ui/icons/Map";
import {MafiInformation} from "@blg/blg-core";
import * as Leaflet from "leaflet";
import {useAppSelector} from "../../store/hooks";

export interface MapComponentProps extends CollapseComponentProps {
    mafiInformation: MafiInformation
}

const MapComponent = (props: MapComponentProps) => {
    const {mode} = useThemeContext();
    const theme = useTheme();
    const {t} = useTranslation();
    const [map, setMap] = useState<Map | null>(null);
    const [movedMap, setMovedMap] = useState<boolean>(false);
    const [darkMode, setDarkMode] = useState(false);
    const [mapUrl, setMapUrl] = useState<string | null>(null);

    const driverLatitude = useAppSelector(state => state.driver).latitude;
    const driverLongitude = useAppSelector(state => state.driver).longitude;
    const isAttached = useAppSelector(state => state.mafi).isAttached;

    let markerBounds = latLngBounds([]);

    useEffect(() => {
        setMapUrl("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
    }, []);

    useEffect(() => {
        if (map) {
            map.on('dragend', function() {
                setMovedMap(true);
            });

            map!.invalidateSize(false); // Trigger reload of the map for the resize
            showMarkerCenter();
        }
    }, [map]);

    useEffect(() => {
        setDarkMode(mode === "dark")
    }, [mode]);

    useEffect(() => {
        showMarkerCenter();
    }, [driverLatitude, driverLongitude]);

    useEffect(() => {
        if (map && !props.isCollapsed) {
            map!.invalidateSize(false); // Trigger reload of the map for the resize
        }
    }, [props.componentSize]);

    function showMarkerCenter() {
        if (movedMap) {
            return;
        }

        const markers = [getDriverPosition()];
        markerBounds = latLngBounds([]);

        if (props.mafiInformation && props.mafiInformation.mafiLocation && props.mafiInformation.mafiLocation.geoLocLatitude) {
            markers.push(getMafiPosition());
        }

        markers.forEach(marker => {
            markerBounds.extend(marker);
        });

        if (map && markerBounds.isValid()) {
            map.fitBounds(markerBounds, {padding: [50, 50] });
        }
    }

    function getDriverPosition(): Leaflet.LatLngExpression {
        return [driverLatitude ?? 0, driverLongitude ?? 0];
    }

    /**
     * Render the driver position on the map
     */
    function getDriverMarker() {
        return (
            <Marker position={getDriverPosition()} icon={isAttached ? tugMafiMarker : driverMarker}>
                <Popup>
                    <Typography variant={"h6"} color={theme.palette.primary.main}>
                        {isAttached ? t("MAP.TUG_MAFI_TITLE") : t("MAP.DRIVER_TITLE")}
                    </Typography>
                </Popup>
            </Marker>
        );
    }

    function getMafiPosition(): Leaflet.LatLngExpression {
        return [Number(props.mafiInformation.mafiLocation.geoLocLatitude),
            Number(props.mafiInformation.mafiLocation.geoLocLongitude)];
    }

    /**
     * Render the mafi position on the map
     */
    function getMafiMarker() {
        if (props.mafiInformation && props.mafiInformation.mafiLocation && props.mafiInformation.mafiLocation.geoLocLatitude) {
            return (
                <Marker position={getMafiPosition()} icon={mafiMarker}>
                    <Popup>
                        <Typography variant={"h6"} color={theme.palette.primary.main}>
                            {props.mafiInformation.mafiNo}
                        </Typography>
                    </Popup>
                </Marker>
            );
        }
    }

    return (
        <Box sx={{height: "100%", width: "100%"}}>
            {props.isCollapsed && props.collapseEvent && props.showCollapseIcons &&
                <Box sx={defaultContentStyle(theme).mapBox}>
                    <Box sx={{position: "absolute", left: "calc(100% - 22px)", top: "calc(0% - 20px)"}}>
                        <IconButton disableRipple onClick={props.collapseEvent!()} color="primary"
                                    sx={defaultContentStyle(theme).iconStyle}>
                            <MapIcon/>
                        </IconButton>
                    </Box>
                </Box>
            }
            {!props.isCollapsed &&
                <Box sx={defaultContentStyle(theme).mapBox}>
                    { /* zIndex must be above 400 to be rendered above the leaflet map */}
                    {props.collapseEvent && props.showCollapseIcons &&
                        <Box sx={{
                            zIndex: 401,
                            position: "absolute",
                            left: "calc(100% - 22px)",
                            top: "calc(0% - 20px)"
                        }}>
                            <IconButton disableRipple onClick={props.collapseEvent()} color="primary"
                                        sx={defaultContentStyle(theme).iconStyle}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    }
                    <Box className={`map ${darkMode ? "dark-mode" : ""}`} sx={{height: "100%"}}>
                        {mapUrl !== null &&
                            <MapContainer
                                center={markerBounds && markerBounds.isValid() ? markerBounds.getCenter() : undefined}
                                ref={setMap}
                                zoom={17}
                                attributionControl={false}
                                scrollWheelZoom={false}
                                style={{height: "100%", width: "100%"}}>
                                <TileLayer
                                    attribution='&copy; OpenStreetMap contributors'
                                    url={mapUrl}
                                />
                                {!isAttached &&
                                    getMafiMarker()
                                }
                                {getDriverMarker()}
                                <AttributionControl position="bottomright" prefix={"Leaflet"}/>
                            </MapContainer>
                        }
                    </Box>

                </Box>
            }
        </Box>
    );
};

export default MapComponent;
