import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Zoom from 'react-medium-image-zoom'
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useState} from "react";
import {Box, Button, DialogActions, DialogContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

import LoginTutorial from "../../assets/images/tutorial/Login.jpg";
import CoupleMafi from "../../assets/images/tutorial/CoupleMafi.jpg";
import DecoupleMafi from "../../assets/images/tutorial/DecoupleMafi.jpg";
import DecoupleMafiStack from "../../assets/images/tutorial/DecoupleMafiStack.jpg";
import JobSelection from "../../assets/images/tutorial/JobSelection.jpg";
import MafiSearch from "../../assets/images/tutorial/MafiSearch.jpg";
import TeamSelection from "../../assets/images/tutorial/TeamSelection.jpg";
import {useAppSelector} from "../../store/hooks";

export interface TutorialDialogProps {
    showDialog: boolean,
    closeEvent(): any,
}

const TutorialDialogComponent = (props: TutorialDialogProps) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    const activeTutorial = useAppSelector(state => state.global).activeTutorial;

    const [navTutorial, setNavTutorial] = useState("LOGIN");

    useEffect(() => {
        setNavTutorial(activeTutorial);
    }, [activeTutorial]);

    useEffect(() => {
        setOpen(props.showDialog);
    }, [props.showDialog])

    const getImg = () => {
        if (navTutorial === "LOGIN")
            return <img src={LoginTutorial}/>
        else if (navTutorial === "COUPLE")
            return <img src={CoupleMafi}/>
        else if (navTutorial === "DECOUPLE")
            return <img src={DecoupleMafi}/>
        else if (navTutorial === "DECOUPLE_STACK")
            return <img src={DecoupleMafiStack}/>
        else if (navTutorial === "JOB_SELECTION")
            return <img src={JobSelection}/>
        else if (navTutorial === "MAFI_SEARCH")
            return <img src={MafiSearch}/>
        else if (navTutorial === "TEAM_SELECTION")
            return <img src={TeamSelection}/>
    };

    return (
        <Box>
            <Dialog
                onClose={() => props.closeEvent()}
                fullWidth
                maxWidth={"xl"}
                open={open}
            >
                <DialogTitle sx={{textAlign: "center"}}>
                    Tutorial
                </DialogTitle>
                <DialogContent sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Zoom>
                        {getImg()}
                    </Zoom>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"outlined"}
                        startIcon={<CloseIcon/>}
                        onClick={props.closeEvent}>
                        {t("GENERAL.INFO_DIALOG.CLOSE")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default TutorialDialogComponent;
