import RefreshIcon from "@mui/icons-material/Refresh";
import {DateTime} from "luxon";
import React, {useEffect} from "react";
import {Box, Button, Card, Grid, IconButton, Theme, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {JobTypeEnum} from "../enum/jobType.enum";
import {useAppSelector} from "../store/hooks";
import {store} from "../store/store";
import {setActiveJobType} from "../store/slices/userSettings";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../routes/routes";
import {useTheme} from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import RouteIcon from "@mui/icons-material/Route";
import {setActiveTutorial, setActiveView} from "../store/slices/global";
import {setLastSearchSettings} from "../store/slices/searchSettings";
import MafiSearchHelper from "../utils/mafiSearchHelper";

/**
 * defines a task type
 */
interface TaskType {
    title: string,
    description: string,
    jobType: JobTypeEnum,
    icon?: "search" | "route",
    withBadge: boolean,
    badgeBackground?: string,
    badgeColor?: string
}

/**
 * view to select between the different tasks of the application,
 * currently three options are supported to select between
 * BYPASS_WITHOUT_ORDER = bypass a mafi without a order from the system (e.g. to clean up)
 * BYPASS_WITH_ORDER = select between different orders (bypass on terminal, load on ship etc.)
 * MAFI_SEARCH = search for specific mafi by prefix, owner etc.
 * @constructor
 */
const TaskSelectionView: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    const lastMafiSync = useAppSelector(state => state.mafi).lastMafiSync;
    let dateString = "-";
    if (lastMafiSync) {
        const dateObject = DateTime.fromISO(lastMafiSync);
        dateString = dateObject.toFormat("dd.MM.yyyy HH:mm:ss")
    }

    const defaultStyles = (theme: Theme) => {
        return {
            contentBox: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: theme.palette.background.paper,
                cursor: "pointer",
                height: "100%",
                width: "100%",
                borderRadius: '20px'
            }
        }
    }

    /**
     * the different task types, a card is drawn for each type inside the view with its title, description, badge etc.
     */
    const taskTypes: Array<TaskType> = [
        {
            title: "TASK_SELECTION.ATTACH",
            description: 'TASK_SELECTION.BYPASS.DESCRIPTION',
            jobType: JobTypeEnum.BYPASS_WITHOUT_ORDER,
            withBadge: false,
            icon: "route",
            badgeColor: theme.palette.secondary.main,
            badgeBackground: '#F9CCD2'
        },
        {
            title: "TASK_SELECTION.MAFI_SEARCH.TITLE",
            description: 'TASK_SELECTION.MAFI_SEARCH.DESCRIPTION',
            jobType: JobTypeEnum.MAFI_SEARCH,
            icon: "search",
            withBadge: false
        }
    ];

    /**
     * resetting the app bar title when the user enters the task selection view
     */
    useEffect(() => {
        store.dispatch(setActiveView({text: '', params: null}));
        store.dispatch(setLastSearchSettings(undefined));
        store.dispatch(setActiveTutorial("JOB_SELECTION"));
    }, []);

    /**
     * is called when a card was clicked, analyses type and goes to corresponding view
     * @param type
     */
    function onCardClicked(type: JobTypeEnum): void {
        store.dispatch(setActiveJobType(type));
        store.dispatch(setActiveView({text: t(`JOB_TITLE.${type}`), params: null}));

        switch (type) {
            case JobTypeEnum.BYPASS_WITHOUT_ORDER:
                navigate(ROUTES.BYPASS_TERMINAL);
                break;
            case JobTypeEnum.BYPASS:
                navigate(ROUTES.ORDER_OVERVIEW);
                break;
            case JobTypeEnum.MAFI_SEARCH:
                navigate(ROUTES.MAFI_SEARCH);
                break;
        }
    }

    return (
        <Box sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: theme.palette.surface.main
        }}>
            <Typography sx={{my: 2}} variant={"h3"}>{t("TASK_SELECTION.TITLE")}</Typography>
            <Typography sx={{mb: 2}}
                        variant={"h5"}>{t("TASK_SELECTION.DESCRIPTION")}</Typography>
            <Grid container spacing={4} sx={{height: "100%", p: 5, display: "flex", justifyContent: "center"}}>
                {taskTypes.map(task => {
                    return (
                        <Grid item xs={4} key={task.jobType}>
                            <Card component={Button} style={defaultStyles(theme).contentBox}
                                  sx={{textTransform: 'none'}}
                                  onClick={() => onCardClicked(task.jobType)}>
                                <Box sx={{
                                    mx: 5,
                                    boxSizing: 'border-box',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    {task.icon == "search" &&
                                        <Box sx={{height: "120px", mb: 4, display: "flex", alignItems: "center"}}>
                                            <SearchIcon
                                                style={{width: 100, height: 100, color: theme.palette.text.primary}}/>
                                        </Box>
                                    }
                                    {task.icon == "route" &&
                                        <Box sx={{height: "120px", mb: 4, display: "flex", alignItems: "center"}}>
                                            <RouteIcon
                                                style={{width: 100, height: 100, color: theme.palette.text.primary}}/>
                                        </Box>
                                    }
                                    <Typography variant={"h4"}>{t(task.title)}</Typography>
                                    <Typography variant={"h6"}>{t(task.description)}</Typography>
                                </Box>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
            <Box sx={{mb: 1, display: 'flex', alignItems: 'center'}}>
                <Typography sx={{mr: 1}}>{t("GENERAL.LAST_SYNC")}: {dateString}</Typography>
                <IconButton onClick={async () => {
                    await MafiSearchHelper.instance.syncOfflineData({
                        showLoadingAnimation: true,
                        hideLoadingText: false
                    });
                }}>
                    <RefreshIcon/>
                </IconButton>
            </Box>
        </Box>
    );
};

export default TaskSelectionView;
