import React from "react";
import {DateTime} from "luxon";
import {useAppSelector} from "../../store/hooks";
import {
    GridFooter,
    GridFooterContainer,
} from "@mui/x-data-grid-premium";
import {Box, IconButton, Typography} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import {useTranslation} from "react-i18next";
import MafiSearchHelper from "../../utils/mafiSearchHelper";

interface MafiSearchCustomFooterProps {
    onReloadMafis: () => void;
}

const MafiSearchCustomFooter = ({onReloadMafis}: MafiSearchCustomFooterProps) => {
    const {t} = useTranslation();
    const lastMafiSync = useAppSelector(state => state.mafi).lastMafiSync;
    let dateString = "-";
    if (lastMafiSync) {
        const dateObject = DateTime.fromISO(lastMafiSync);
        dateString = dateObject.toFormat("dd.MM.yyyy HH:mm:ss")
    }

    return (
        <GridFooterContainer>
            <Box sx={{ml: 3, display: 'flex', alignItems: 'center'}}>
                <Typography sx={{mr: 1}}>{t("GENERAL.LAST_SYNC")}: {dateString}</Typography>
                <IconButton onClick={async () => {
                    await MafiSearchHelper.instance.syncOfflineData({
                        showLoadingAnimation: true,
                        hideLoadingText: false
                    });

                    // Trigger the refresh after 1 second, to save the data in the database
                    setTimeout(() => {
                        onReloadMafis();
                    }, 1000);
                }}>
                    <RefreshIcon/>
                </IconButton>
            </Box>
            <GridFooter/>
        </GridFooterContainer>
    );
};

export default MafiSearchCustomFooter;
