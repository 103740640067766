import React, {useEffect, useState} from 'react';

import {Box, Backdrop} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {Outlet, useNavigate} from "react-router-dom";
import LoadingImageWhite from "../assets/images/loading-animation-white.svg";
import {ROUTES} from "../routes/routes";
import LoadingImage from "../assets/images/loading-animation.svg";
import {useAppSelector} from "../store/hooks";

import { useLocation } from "react-router-dom"
import {useThemeContext} from "../theme/themeContextProvider";

function DefaultView() {
    const { isDarkMode } = useThemeContext();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    let timer: NodeJS.Timeout;

    const token = useAppSelector(state => state.auth).token;

    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);

    useEffect(() => {
        setShowLoadingAnimation(true);
        return () => {
            setShowLoadingAnimation(false);
            if (timer) {
                clearInterval(timer);
            }
        }
    }, []);

    useEffect(() => {
        if (token) {
            timer = setTimeout(() => {
                setShowLoadingAnimation(false);
                if (location.pathname === "/login") {
                    return;
                }

                void continueRouting();
            }, 500);
        } else {
            timer = setTimeout(() => {
                setShowLoadingAnimation(false);
                navigate(ROUTES.LOGIN);
            }, 500);

        }
    }, [token]);

    async function continueRouting() {
        if (location.pathname === "/") {
            navigate(ROUTES.HOME);
        } else {
            navigate(location.pathname);
        }
    }

    return (
        <Box sx={{ backgroundColor: theme.palette.primary.main, height: "100vh", width: "100%",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Box sx={{ position: "absolute", top: 0, left: 0 }}>
                <Backdrop
                    sx={{
                        backgroundColor: `${ isDarkMode ? "rgba(36, 26, 5,0.8)": "rgba(222,224,233,0.9)" }`,
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    open={showLoadingAnimation}
                >
                    <object type="image/svg+xml" style={{width: 275, height: 275}}
                            data={isDarkMode ? LoadingImageWhite : LoadingImage}>svg-animation
                    </object>
                </Backdrop>
            </Box>
            <Outlet />
        </Box>
    );
}

export default DefaultView;
