import {MafiCategory, MafiInformation, MafiOwner, MafiPrefix} from "@blg/blg-core";
import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export interface MafiState {
    selectedMafi?: MafiInformation
    isAttached: boolean,
    attachedMafi?: MafiInformation,
    lastMafiSync?: string,
    searchOptions?: { MafiOwners: MafiOwner[], MafiPrefix: MafiPrefix[], MafiCategories: MafiCategory[] }
}

const initialState: MafiState = {
    selectedMafi: undefined, isAttached: false, attachedMafi: undefined, lastMafiSync: undefined
};

const mafiState = createSlice({
    name: "mafi",
    initialState,
    reducers: {
        setSelectedMafi(state: MafiState, action: PayloadAction<MafiInformation | undefined>) {
            state.selectedMafi = action.payload;
        },
        setAttachedMafi(state: MafiState, action: PayloadAction<MafiInformation | undefined>) {
            state.attachedMafi = action.payload;
        },
        setIsAttached(state: MafiState, action: PayloadAction<boolean>) {
            state.isAttached = action.payload;
        },
        setLastMafiSync(state: MafiState, action: PayloadAction<string | undefined>) {
            state.lastMafiSync = action.payload;
        },
        setSearchOptions(state: MafiState, action: PayloadAction<{ MafiOwners: MafiOwner[], MafiPrefix: MafiPrefix[], MafiCategories: MafiCategory[] }>) {
            state.searchOptions = action.payload;
        }
    },
});

export const {setSelectedMafi, setIsAttached, setAttachedMafi, setLastMafiSync, setSearchOptions} = mafiState.actions;
export default mafiState.reducer;
