import {store} from "../store/store";
import {setAttachedMafi, setIsAttached} from "../store/slices/mafi";
import { MafiInformation } from "@blg/blg-core";
import TugService from "@blg/blg-core/lib/esm/services/roTrail/roTrailTugService";
import {SnackbarUtil} from "@blg/blg-core";

export async function coupleMafi(information: MafiInformation) {
    store.dispatch(setIsAttached(true));
    store.dispatch(setAttachedMafi(information));

    await TugService.instance.coupleMafiById(information.id);
}

export async function decoupleMafi(information: MafiInformation): Promise<boolean> {
    try {
        await TugService.instance.decoupleMafiById(information.id);
        store.dispatch(setIsAttached(false));
        store.dispatch(setAttachedMafi(undefined));

        return true;
    } catch (e) {
        console.log('e', e);
        SnackbarUtil.error("Fehler beim Abkoppeln");
        return false;
    }
}

/**
 * Get the correct mafiNo for the update. If it is a stack, use the parent mafiNo
 * If not, use the mafiNo of the selected mafi
 * @param mafi
 */
export function getUpdateMafiNo(mafi: MafiInformation): string {
    if (mafi.stack && mafi.stack.length > 0) {
        return mafi.stack[0];
    }

    return mafi.mafiNo;
}
