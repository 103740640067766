import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import FilterElementComponent from "./filterElement.component";

interface LashingMethodFilterContainerProps {
    lashingMethods: string[],
    currentSelectedMethods: string[],
    onLashingMethodChanged: (lashingMethod: string[]) => void
}

const LashingMethodFilterContainerComponent = (props: LashingMethodFilterContainerProps) => {
    const {t} = useTranslation();

    function onCurrentMethodChanged(method: string) {
        if (props.currentSelectedMethods.indexOf(method) === -1) {
            props.onLashingMethodChanged([...props.currentSelectedMethods, method]);
        } else {
            props.onLashingMethodChanged(props.currentSelectedMethods.filter(m => m !== method));
        }
    }

    const allFilterElements = props.lashingMethods.map(method =>
        <FilterElementComponent
            text={t(`GENERAL.${method}`)}
            value={method}
            isSelected={props.currentSelectedMethods.indexOf(method) !== -1}
            key={method}
            onClick={onCurrentMethodChanged}/>);

    return <Box display={'flex'} flexDirection={'column'}>
        <Typography sx={{ textDecoration: 'underline' }} variant={"h6"}>
            {t('TASK_SELECTION.MAFI_SEARCH.FILTER_DIALOG.LASHING_METHOD')}
        </Typography>
        {allFilterElements}
    </Box>;
}

export default LashingMethodFilterContainerComponent;
