import {useTheme} from "@mui/material/styles";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect, useState} from "react";
import {Box, DialogContent, Checkbox} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import GefahrenNaesseIcon from "../../assets/images/GefahrenNaesse.jpg";
import NaesseIcon from "../../assets/images/naesseempfindlich.svg";
import GefahrenTafel from "../../assets/images/gefahrgutwarntafel.svg";
import {defaultTableStyle} from "../../styles/defaultStyles";
import {TableInformation} from "@blg/blg-core";
import { GoodsData } from "@blg/blg-core/lib/esm/models/roTrail/GoodsDataInterface";

export interface LoadedGoodsDialogProps {
    goods: GoodsData[],
    showDialog: boolean,

    closeEvent(): any,
}

const LoadedGoodsDialogComponent = (props: LoadedGoodsDialogProps) => {
    const {t} = useTranslation();
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const [columns, setColumns] = useState<Array<any>>([]);

    const tableHeaders: TableInformation[] = [{
        field: 'dangerousGood',
        headerName: t("FINISH_LOAD_GOODS.INFOS"),
        headerClassName: 'cargotable-header',
        disableColumnMenu: true,
        sortable: false,
        renderCell(rowData: any): any {
            if (rowData.row.dangerousGoods && rowData.row.wetSensitive) {
                return (<img style={{width: 25, height: 25}} src={GefahrenNaesseIcon} alt="GefahrentafelNaesse"/>)
            } else if (rowData.row.wetSensitive) {
                return (<img
                    style={{width: 25, height: 25}}
                    src={NaesseIcon}
                    alt="N&auml;sseempfindlich"
                />)
            } else if (rowData.row.dangerousGoods) {
                return (<img
                    style={{width: 25, height: 25}}
                    src={GefahrenTafel}
                    alt="Gefahrentafel"
                />)
            } else {
                return (<Box></Box>)
            }
        }
    }, {
        field: 'description',
        headerName: t("FINISH_LOAD_GOODS.DESCRIPTION"),
        headerClassName: 'cargotable-header',
        disableColumnMenu: true,
        flex: 1
    }, {
        field: 'identNo',
        headerName: t("FINISH_LOAD_GOODS.IDENT"),
        headerClassName: 'cargotable-header',
        disableColumnMenu: true,
        flex: 1
    },{
        field: 'chained',
        headerName: t("FINISH_LOAD_GOODS.CHAINED"),
        headerClassName: 'cargotable-header',
        disableColumnMenu: true,
        sortable: false,
        renderCell(rowData: any): any {
            return <Checkbox disabled checked={rowData.row.chained} />
        }
    }, {
        field: 'selfDrivable',
        headerName: t("FINISH_LOAD_GOODS.SELF_DRIVABLE"),
        headerClassName: 'cargotable-header',
        disableColumnMenu: true,
        sortable: false,
        renderCell(rowData: any): any {
            return <Checkbox disabled checked={rowData.row.selfDrivable} />
        }
    }, {
        field: 'pulling',
        headerName: t("FINISH_LOAD_GOODS.PULLING"),
        headerClassName: 'cargotable-header',
        disableColumnMenu: true,
        sortable: false,
        renderCell(rowData: any): any {
            return <Checkbox disabled checked={rowData.row.pulling} />
        }
    }, {
        field: 'nonDrivable',
        headerName: t("FINISH_LOAD_GOODS.NON_DRIVABLE"),
        headerClassName: 'cargotable-header',
        disableColumnMenu: true,
        sortable: false,
        renderCell(rowData: any): any {
            return <Checkbox disabled checked={rowData.row.nonDrivable} />
        }
    }, {
        field: 'noLashingPoint',
        headerName: t("FINISH_LOAD_GOODS.NO_LASHING_POINT"),
        headerClassName: 'cargotable-header',
        disableColumnMenu: true,
        sortable: false,
        width: 125,
        renderCell(rowData: any): any {
            return <Checkbox disabled checked={!rowData.row.noLashingPoint} />
        }
    }];

    useEffect(() => {
        setColumns(tableHeaders);
    }, []);

    useEffect(() => {
        setOpen(props.showDialog);
    }, [props.showDialog])

    return (
        <Box>
            <Dialog
                onClose={() => props.closeEvent()}
                fullWidth
                maxWidth={"xl"}
                open={open}
            >
                <DialogTitle sx={{textAlign: "center"}}>

                </DialogTitle>
                <DialogContent>
                    <DataGridPremium
                        getRowId={() => Math.random().toString(16).slice(2)}
                        autoHeight
                        sx={defaultTableStyle(theme)}
                        columnHeaderHeight={40}
                        key="table"
                        rows={props.goods}
                        columns={columns}
                        hideFooter={props.goods.length === 1}
                        paginationModel={{ pageSize: 25, page: 0 }}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default LoadedGoodsDialogComponent;
