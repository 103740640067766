import {MafiFilterEntry} from "../components/mafiSearch/filterElement.component";

export const allLengthEntries: MafiFilterEntry[] = [{
    id: 1,
    text: "0 - 29",
    min: 0,
    max: 29
}, {
    id: 2,
    text: "30",
    min: 30,
    max: 39
}, {
    id: 3,
    text: "40",
    min: 40,
    max: 59
}, {
    id: 4,
    text: "60",
    min: 60,
    max: 69
}, {
    id: 5,
    text: "70",
    min: 70,
    max: 79
}, {
    id: 6,
    text: "80+",
    min: 80
}];

export const allTaraEntries: MafiFilterEntry[] = [{
    id: 1,
    text: "< 100t",
    min: 0,
    max: 99999
}, {
    id: 2,
    text: "100t",
    min: 100000,
    max: 119999
}, {
    id: 3,
    text: "120t",
    min: 120000,
    max: 139999
}, {
    id: 4,
    text: "140t +",
    min: 140000,
}];

export const allLashingMethods: string[] = ["LASHABLE", "TRANSPORT_LUG"];
export const allLoadingStatus: string[] = ["VOLL", "LEER"];
