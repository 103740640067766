import {Theme} from "@mui/material";

export const defaultStyles = (theme: Theme) => {
    return {
        contentBox: {
            borderRadius: "6px",
            backgroundColor: theme.palette.background.default
        }
    }
}

export function defaultTableStyle(theme: Theme) {
    return {
        height: "100%",
        backgroundColor: theme.palette.background.default,
        '& .cargotable-centeredHeader': {
            backgroundColor: theme.palette.background.default,
            '.MuiDataGrid-columnHeaderTitleContainer': {
                padding: 0,
                justifyContent: 'center'
            },
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
            }
        },
        '& .cargotable-header': {
            backgroundColor: theme.palette.background.default,
            '.MuiDataGrid-columnHeaderTitleContainer': {
                padding: 0,
            },
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
            }
        },
    }
}
