export enum ROUTES {
  ROOT = "/",
  HOME = "/home",
  LOGIN = "/login",
  BYPASS_TERMINAL = '/home/bypass-terminal',
  TASK_SELECTION = "/home/tasks",
  BYPASS_DETAILS = '/home/tasks/bypass-detail/:orderId',
  MAFI_SEARCH = '/home/tasks/mafi-search',
  MAFI_DETAILS = '/home/tasks/mafi-details',
  ORDER_OVERVIEW = '/home/tasks/order-overview',
  UNSTACK_DETAILS = '/home/unstack/:mafiNo',
}
