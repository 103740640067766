import {Checkbox, FormControlLabel, Typography} from "@mui/material"
import {useTheme} from "@mui/material/styles";

interface FilterElementProps {
    text: string,
    value: string | number,
    isSelected: boolean,
    onClick: (value: any) => void
}

export interface MafiFilterEntry {
    id: number,
    text: string,
    min: number,
    max?: number
}

const FilterElementComponent = (props: FilterElementProps) => {
    const theme = useTheme();

    return <FormControlLabel
        onClick={(event) => {
            props.onClick(props.value)
            event.preventDefault()
        }}
        sx={{ mt: 1, pl: 1,
            backgroundColor: `${props.isSelected ? theme.palette.surface.main : "transparent"}` }}
        checked={props.isSelected}
        control={<Checkbox size={"large"}/>}
        label={<Typography variant="h6">{props.text}</Typography>}/>
}

export default FilterElementComponent;
