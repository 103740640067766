import React from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOff";
import {Box, FormControl, InputAdornment, InputLabel, MenuItem, Select} from "@mui/material";
import {useAppSelector} from "../../store/hooks";
import {useThemeContext} from "../../theme/themeContextProvider";
import {useTranslation} from "react-i18next";
import {AvailableSearchOptions} from "../../utils/mafiSearchHelper";

interface MafiOwnerFilterSelectProps {
    selectedPrefix: string;
    onChange: (prefix: string) => void;
    availableSearchOptions?: AvailableSearchOptions;
}

const MafiOwnerFilterSelectComponent = ({selectedPrefix, onChange, availableSearchOptions}: MafiOwnerFilterSelectProps) => {
    const {isDarkMode} = useThemeContext();
    const {t} = useTranslation();

    const mafiPrefix = useAppSelector(state => state.searchSettings).searchPrefix;
    const sortedPrefix = [...mafiPrefix].sort((a, b) => a.prefix.localeCompare(b.prefix));

    return (
        <Box sx={{backgroundColor: 'white', borderRadius: "6px", height: "56px"}}>
            <FormControl className={isDarkMode ? "mafi-search" : ""} variant={"filled"}
                         sx={{width: '100%'}}>
                <InputLabel id={"prefix-label"}>{t('GENERAL.PREFIX')}</InputLabel>
                <Select
                    labelId={"prefix-label"}
                    sx={{padding: 0, margin: 0, width: '100%'}}
                    endAdornment={
                        <InputAdornment
                            position="end"
                            onClick={() => {
                                onChange("ALL");
                            }}
                            sx={{pointer: "cursor", zIndex: 1000, position: "absolute", right: 35}}>
                            <HighlightOffOutlinedIcon color={"primary"}/>
                        </InputAdornment>
                    }
                    onChange={(event) => {
                        if (event.target.value === "ALL") {
                            onChange("ALL");
                        } else {
                            const prefix = sortedPrefix.find(pre => pre.prefix === event.target.value);
                            onChange(prefix ? prefix.prefix : "ALL");
                        }
                    }}
                    value={selectedPrefix} variant={"filled"}>
                    <MenuItem value={"ALL"}>{t('GENERAL.ALL')}</MenuItem>
                    {sortedPrefix.map((c) =>
                        <MenuItem
                            key={c.id}
                            disabled={availableSearchOptions && !availableSearchOptions?.prefix.includes(c.prefix)}
                            value={c.prefix}>{c.prefix}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    )
}

export default MafiOwnerFilterSelectComponent;
