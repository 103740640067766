import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import FilterElementComponent, {MafiFilterEntry} from "./filterElement.component";

interface LengthFilterContainerProps {
    allLengthEntries: MafiFilterEntry[],
    currentSelectedLength: MafiFilterEntry[],
    onLengthChanged: (length: MafiFilterEntry[]) => void
}

const LengthFilterContainerComponent = (props: LengthFilterContainerProps) => {
    const {t} = useTranslation();

    function onCurrentLengthChanged(tara: number) {
        const selectedTara = props.allLengthEntries.find(l => l.id === tara);
        if (props.currentSelectedLength.find(t => t.id === selectedTara!.id) === undefined) {
            props.onLengthChanged([...props.currentSelectedLength, selectedTara!]);
        } else {
            props.onLengthChanged(props.currentSelectedLength.filter(l => l.id !== selectedTara!.id));
        }
    }

    const allFilterElements = props.allLengthEntries.map(length =>
        <FilterElementComponent
            text={length.text}
            value={length.id}
            isSelected={props.currentSelectedLength.find(t => t.id === length.id) !== undefined}
            key={length.id}
            onClick={onCurrentLengthChanged}/>);

    return <Box display={'flex'} flexDirection={'column'}>
        <Typography sx={{ textDecoration: 'underline' }} variant={"h6"}>
            {t('TASK_SELECTION.MAFI_SEARCH.FILTER_DIALOG.LENGTH')}
        </Typography>
        {allFilterElements}
    </Box>;
}

export default LengthFilterContainerComponent;
