import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {JobTypeEnum} from "../../enum/jobType.enum";

export interface UserSettingsState {
  activeJobType?: JobTypeEnum,
}

const initialState: UserSettingsState = {
  activeJobType: undefined
};

const userSettingsState = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setActiveJobType(state: UserSettingsState, action: PayloadAction<JobTypeEnum>) {
      state.activeJobType = action.payload;
    },
  },
});

export const { setActiveJobType } = userSettingsState.actions;
export default userSettingsState.reducer;
